/** SDD:
 * View Change Log (Read only)
 * The Change log will show all changes to the data and allow the user to filter on the column name, the date of change, and the changed values.
 */

import React from 'react';
import { connect } from 'react-redux';
import { setPageTitleAction, showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import APIEndPoints from '../services/api';
import { DrcPanel, DrcButton, DrcDataGrid, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { Middleware } from '@driscollsinc/one-ring';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import GridStyles from '../../../styles/gridStyles';
import { formatApiResponse, makeColumns } from '../../../utils/helper';
import { boolColumns, dateFields, templateColumnsMap } from '../../../data/constants';
import Filter from '../../../components/filter/Filter';
import { ReactComponent as Download } from '../../../assets/Download.svg';
const PAGE_TITLE = 'Log';

const styles = (theme) => ({
    main: {
        minHeight: 'calc(100vh - 200px)',
        display: 'flex',
        justifyContent: 'center'
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    unfrozen: {
        '& .p-datatable-unfrozen-view': {
            position: 'relative !important'
        }
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    panel: {
        width: '100vw',
        maxWidth: 'calc(100vw - 100px) !important',
        border: 'none',
        padding: 0,
        margin: 0
    },
    icon: {
        width: '24px',
        height: '24px',
        fill: theme.palette.primary.main,
        marginRight: '4px',
        marginTop: '-2px'
    }
});

class LogHome extends React.Component {
    state = {
        logsList: [],
        pageNum: 0,
        selectedFiles: [],
        pageSize: 50,
        totalLogs: 0,
        columns: [],
        showUploadDialog: false,
        showFilter: false
    };
    excludingFields = ['Actions', 'Id'];
    prevEntityId = null;
    readOnly = false;
    prevRowType = 'even';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;
    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.loadLogs();
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.berry !== this.props.berry) {
            this.applyFilter(true);
        }

        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
    }

    formatRowsData = (data) => formatApiResponse(data, dateFields, boolColumns, false);

    loadLogs = async (selection = '', status = '', cycle = '', location = '', fieldcode = '') => {
        let token = await this.props.oktaAuth.getAccessToken();
        await Middleware.Send(
            '',
            token,
            APIEndPoints.GET_LOG_DATA(
                this.state.pageNum / this.state.pageSize + 1,
                this.state.pageSize,
                this.props.berry,
                selection,
                status,
                cycle,
                location,
                fieldcode
            ),
            'GET',
            ''
        )
            .then((response) => {
                this.response = this.markChanges(response);
                this.updateDataTable(null, true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    markChanges = (data) => {
        let mData = data.Data.map((elm, index, arr) => {
            //finding the next element of same combination (same summary data id or selection + fieldcode+cycle combination)
            let nextElement = this.getNextElement(arr.slice(index + 1), elm.SummaryDataId, elm.Selection, elm.FieldCode);
            let changedProps = [];
            if (nextElement) {
                Object.keys(nextElement).map((key) => {
                    if (nextElement[key] && elm[key] && nextElement[key].toString().trim('') !== elm[key].toString().trim('')) {
                        changedProps.push(key.toLowerCase());
                    }
                    return;
                });
            }
            elm.changes = changedProps;
            return elm;
        });
        data.Data = mData;
        return data;
    };

    getNextElement = (data, summaryDataId, selection, fieldCode, cycle) => {
        return data.find((elm) => {
            if (elm.SummaryDataId === summaryDataId || (elm.Selection === selection && elm.FieldCode === fieldCode && elm.Cycle === cycle)) {
                return elm;
            }
        });
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let logData = response.TotalCount === 0 ? [] : this.formatRowsData(JSON.parse(JSON.stringify(response.Data || [])));
        let columns = logData.length ? makeColumns(Object.keys(logData[0]), this.excludingFields, null, false, hasFrozenColumns) : {};
        this.setState({
            logsList: logData,
            totalLogs: response.TotalCount,
            columns: columns,
            unfreeze: !hasFrozenColumns
        });
    };
    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadLogs();
    };

    getRowCount = () => {
        if (this.state.totalLogs < this.state.pageSize) {
            return this.state.totalLogs;
        }

        return this.state.pageSize;
    };
    rowClassName = (rowData) => {
        if (rowData.Id === this.state.logsList[0].Id) {
            this.prevRowType = 'even';
        }
        if (this.prevId === rowData.Id) {
            return { [this.prevRowType]: true };
        } else {
            this.prevId = rowData.Id;
            this.prevRowType = this.prevRowType === 'even' ? 'odd' : 'even';
            return { [this.prevRowType]: true };
        }
    };

    getDownloadColumns = () => {
        let excludedColumns = [];
        return Object.values(templateColumnsMap)
            .filter((col) => !excludedColumns.includes(col))
            .map((key) => ({ name: key, key }));
    };

    /**
     * Shows a message/loadingscreen to the user.
     * Make sure the translations for the text exist in appTranslations.
     * @function showMessage
     * @param {string} messageTitle - The title to display in the messageBox
     * @param {string} message - The message to display in the messageBox.
     * @fires showLoadingScreenAction
     */
    showMessage = (messageTitle, message) => {
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>
                    <DrcTranslate>{messageTitle}</DrcTranslate>
                </h4>
                <div>
                    <DrcTranslate>{message}</DrcTranslate>
                </div>
            </React.Fragment>
        );
    };

    /**
     * Closes any message/loadingscreen opened by {@link showMessage} for the user.
     * @function hideMessage
     * @fires hideLoadingScreenAction
     */
    hideMessage = () => {
        this.props.hideLoadingScreenAction();
    };

    /**
     * Method that fetches all data for the current filters and downloads them in a excel spreadsheet
     * @async
     * @function downloadData
     */
    downloadData = async () => {
        // show downloading message
        this.showMessage('Preparing download', 'Your download should start in a few seconds.');

        // prepare parameters
        let token = await this.props.oktaAuth.getAccessToken();
        let filter = this.props.filters.reduce((filter, row) => {
            if (row.hasOptions || row.isAsync) {
                filter[row.fieldName] = row.selected?.value;
            } else if (row.isAlphanumeric) {
                filter[row.fieldName] = row.value;
            }
            return filter;
        }, {});

        // call API to fetch all the data
        await Middleware.Send(
            '',
            token,
            APIEndPoints.GET_LOG_DATA(
                1,
                this.state.totalLogs,
                this.props.berry,
                filter['Selection'],
                filter['Status'],
                filter['Cycle'],
                filter['Location'],
                filter['FieldCode']
            ),
            'GET',
            ''
        )
            .then((response) => {
                // convert data for spreadheet
                let logData = this.formatRowsData(JSON.parse(JSON.stringify(response.Data)));
                let formattedData = logData.map((row) => {
                    const keyValues = Object.keys(row).map((key) => {
                        return { [templateColumnsMap[key]]: row[key] };
                    });
                    return Object.assign({}, ...keyValues);
                });

                // create xlsx
                DuExcelUtilities.Write('LogData.xlsx', this.getDownloadColumns(), formattedData);
            })
            .catch((error) => {
                console.log(error);
                this.props.showToast('Unable to create download', 'error');
            });
        // hide the downloading message
        this.hideMessage();
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });
    resetFilter = () => {
        this.loadLogs();
    };
    applyFilter = (refreshData = false) => {
        let filter = this.props.filters.reduce((filter, row) => {
            if (row.hasOptions || row.isAsync) {
                filter[row.fieldName] = row.selected?.value;
            } else if (row.isAlphanumeric) {
                filter[row.fieldName] = row.value;
            }
            return filter;
        }, {});
        if (refreshData) {
            return this.resetFilter();
        }
        // if (!refreshData && !filter['Selection']) {
        //     return this.resetFilter();
        // }
        this.loadLogs(filter['Selection'], filter['Status'], filter['Cycle'], filter['Location'], filter['FieldCode']);
    };

    render() {
        const { classes } = this.props;

        return (
            <DrcPanel className={classes.panel}>
                <div className={classes.filterContainer}>
                    <Filter showFilter={this.state.showFilter} applyFilter={this.applyFilter} resetFilter={this.resetFilter} />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    <div>
                        <DrcButton onClick={this.downloadData}>
                            <Download className={classes.icon} />
                            <DrcTranslate>{'ExportData'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                {this.state.logsList.length ? (
                    <DrcDataGrid
                        paginator={true}
                        currentPage={this.state.pageNum}
                        onPage={this.onPage}
                        columns={this.state.columns}
                        rows={this.state.logsList}
                        gridStyles={this.state.unfreeze ? `${classes.gridStyles} ${classes.unfrozen}` : classes.gridStyles}
                        totalRecords={this.state.totalLogs}
                        resultCount={this.state.totalLogs}
                        pageSize={this.state.pageSize}
                        rowsToLoadPerScroll={this.getRowCount()}
                        rowClassName={this.rowClassName}
                        rowGroupMode="rowspan"
                        lazy={true}
                        virtualRowHeight={48}
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        {...(!this.readOnly ? { frozenWidth: this.state.unfreeze ? '0px' : '650px' } : undefined)}
                    />
                ) : (
                    <DrcTranslate>{'No records to display'}</DrcTranslate>
                )}
            </DrcPanel>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        filters: state.summaryReducer.filters,
        berry: state.masterReducer.berry
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(withStyles(styles)(LogHome))));
