/** SDD:
 * Template List
 * We will have the application setup with Templates which have Fields which can have Lookup Lists.
 * We will need to store these custom attributes which includes the data type they are expecting along with a length maximum.
 * In this page we will show all templates that have been created thus far and allow the user to add new templates.
 */
import React from 'react';
import { connect } from 'react-redux';
import { DrcMain, DrcPanel, DrcButton } from '@driscollsinc/driscolls-react-components';
import { setPageTitleAction } from '../../../actions/actions';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { setTemplateDataAction } from '../actions/TemplateActions';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import { ReactComponent as Import } from '../../../assets/Import.svg';
import { ReactComponent as Add } from '../../../assets/Add.svg';

const PAGE_TITLE = 'Template List';

const styles = (theme) => ({
    container: {
        backgroundColor: theme.light.primary,
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.primary
        }
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    panel: {
        width: '100vw',
        maxWidth: 'calc(100vw - 100px) !important',
        border: 'none',
        padding: 0,
        margin: 0
    },
    icon: {
        width: '24px',
        height: '24px',
        fill: theme.palette.primary.main,
        marginRight: '4px',
        marginTop: '-2px'
    }
});

class TemplatesHome extends React.Component {
    state = {
        templatesList: []
    };
    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.getTemplates();
    }
    getTemplates = async () => {};
    render() {
        const { isMasterDataInitialized, classes } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcMain transparent>
                <DrcPanel className={classes.panel}>
                    <div className={classes.pageActions}>
                        <div>
                            <DrcButton onClick={this.toggleShowFilter}>
                                <FilterListSharp className={classes.icon} />
                                Filter
                            </DrcButton>
                        </div>
                        <div>
                            <DrcButton onClick={() => {}}>
                                <Import className={classes.icon} />
                                Import
                            </DrcButton>
                            <DrcButton onClick={() => {}}>
                                <Add className={classes.icon} />
                                New Template
                            </DrcButton>
                        </div>
                    </div>
                </DrcPanel>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        template: state.templateReducer.template
    };
}

const mapDispatchToProps = (dispatch) => ({
    setTemplateData: (template) => dispatch(setTemplateDataAction(template)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(withStyles(styles)(TemplatesHome))));
