import { DuValidationUtilities } from '@driscollsinc/driscolls-react-utilities';

const allowedNumberValuesRegex = RegExp(/^[0-9]*$/);
const allowedDecimalValuesRegex = RegExp(/^[0-9]*(\.[0-9]*)?$/);

let validateTextLength = (value, column, required) => {
    if (!isNaN(column.minLength) && (value || '').length < column.minLength) {
        return 'Required Length Too Small';
    } else if (!isNaN(column.maxLength) && (value || '').length > column.maxLength) {
        return 'Required Length Too Large';
    } else if (required && value === '') {
        return 'This field is required';
    }

    return '';
};

let validateMasterDataFields = (column, dataType, value, required) => {
    let helpText;
    switch (dataType) {
        case 'number':
            if (!allowedNumberValuesRegex.test(value) || isNaN(Number(value))) {
                helpText = 'Please Remove Illegal Characters';
            } else {
                if (!isNaN(column.minValue) && Number(value) < column.minValue) {
                    helpText = 'Please Use a Larger Value';
                } else if (!isNaN(column.maxValue) && Number(value) > column.maxValue) {
                    helpText = 'Please Use a Smaller Value';
                } else {
                    helpText = validateTextLength(value, column);
                }
            }
            break;
        case 'decimal':
            if (!allowedDecimalValuesRegex.test(value) || isNaN(Number(value))) {
                helpText = 'Please Remove Illegal Characters';
            } else {
                if (!isNaN(column.minValue) && Number(value) < column.minValue) {
                    helpText = 'Please Use a Larger Value';
                } else if (!isNaN(column.maxValue) && Number(value) > column.maxValue) {
                    helpText = 'Please Use a Smaller Value';
                } else {
                    helpText = validateTextLength(value, column);
                }
            }
            break;
        case 'required':
            if (value === '') {
                helpText = 'This field is required';
            } else {
                helpText = validateTextLength(value, column);
            }
            break;
        case 'regex':
            if (!column.regex.test(value)) {
                helpText = column.regexDescription || 'Please Correct Input';
            } else {
                helpText = validateTextLength(value, column);
            }
            break;
        case 'email':
            if (!DuValidationUtilities.EMAIL_REGEXP.test(value)) {
                helpText = column.regexDescription || 'Please Add a Valid Email';
            } else {
                helpText = validateTextLength(value, column);
            }
            break;
        case 'userId':
            if (!DuValidationUtilities.USER_ID_REGEX.test(value)) {
                helpText = column.regexDescription || 'Please Add a Valid User Id';
            } else {
                helpText = validateTextLength(value, column);
            }
            break;
        default:
            helpText = validateTextLength(value, column, required);
            break;
    }
    return { helpText, value };
};
let getEditorType = (dataType, key) => {
    let editorType;
    switch (dataType) {
        case 'text':
            editorType = 'text';
            break;
        case 'int':
            editorType = 'int';
            break;
        case 'float':
            editorType = 'decimal';
            break;
        case 'Attribute':
            editorType = key !== 'BerryType' ? 'select' : 'berryselect';
            break;
        case 'datetime':
            editorType = 'date';
            break;
        case 'bool':
            editorType = 'boolean';
            break;
        default:
            editorType = 'decimal';
    }
    return editorType;
};

let getSelectionOptions = (optionLabel, optionValue, options) => {
    let arrOptions = [{ label: 'No Value', value: 'No Value' }];
    if (optionLabel && optionValue && options && options.length > 0) {
        arrOptions = options
            .map((item) => {
                if (item[optionLabel] && item[optionValue]) return { label: item[optionLabel], value: item[optionValue] };
            })
            .filter((op) => {
                return op !== undefined;
            });
    }
    return arrOptions;
};

const MasterDataValidation = {
    getEditorType: getEditorType,
    getSelectionOptions: getSelectionOptions,
    validateMasterDataFields: validateMasterDataFields
};

export default MasterDataValidation;
