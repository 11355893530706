import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { DrcInput, DrcButton, DrcDialog, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { withStyles } from '@material-ui/styles';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { startOfWeek, endOfWeek } from 'date-fns';

const styles = (theme) => ({
    root: {
        marginTop: '5px !important',
        marginBottom: '5px',
        '& .MuiButtonBase-root.MuiIconButton-root': {
            marginRight: '-15px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.buttonBorder + theme.important
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.accent.primary + theme.important
        },
        '& .MuiInputAdornment-root button': {
            color: theme.light.text.primary + theme.important
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.text.primary + theme.important
        },
        [theme.darkTheme]: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.buttonBorder + theme.important
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.dark.text.primary + theme.important
                }
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.accent.primary + theme.important
            },
            '& input.MuiInputBase-input': {
                color: theme.dark.text.primary + theme.important
            },
            '& .MuiInputAdornment-root button': {
                color: theme.dark.text.primary + theme.important
            }
        }
    },
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1200px !important' //TO override dialog maxWidth
        }
    },
    popup: {
        '@media (prefers-color-scheme: dark)': {
            filter: 'invert(1)',
            boxShadow: '0px -1px 45px -8px rgba(92,92,92,1)'
        },
        boxShadow: theme.light.shadow[0] + ', ' + theme.light.shadow[0],
        minHeight: 'max-content',
        background: 'white'
    },
    actionButtons: {
        margin: '5% !important'
    },
    labelError: {
        color: theme.light.text.errorTitle,
        fontSize: '1.25rem',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class DrcDateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectionRange: {
                startDate: props.startDate === null || props.startDate ? props.startDate : new Date(),
                endDate: props.endDate === null || props.endDate ? props.endDate : null,
                color: props.color ? props.color : '#4a773c',
                key: props.key ? props.key : 'selection',
                autoFocus: props.autoFocus ? props.autoFocus : true,
                disabled: props.disabled ? props.disabled : false,
                showDateDisplay: props.showDateDisplay ? props.showDateDisplay : true
            }
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    };

    handleGlobalClick = (e) => {
        if (this.node && this.node.contains(e.target)) return;
        this.setState({ show: false });
        document.removeEventListener('click', this.handleGlobalClick);
    };

    handleChange = (ranges) => {
        let newSelectionRange;
        if (!this.props.weekDisplay) {
            newSelectionRange = { ...this.state.selectionRange, startDate: ranges.selection.startDate, endDate: ranges.selection.endDate };
        } else {
            newSelectionRange = {
                ...this.state.selectionRange,
                startDate: startOfWeek(new Date(ranges.selection.startDate)),
                endDate: endOfWeek(new Date(ranges.selection.endDate))
            };
        }
        this.setState({ selectionRange: newSelectionRange });
        if (this.props.onChange) this.props.onChange(newSelectionRange.startDate, newSelectionRange.endDate);
    };

    handleReset = () => {
        let resetSelectionRange;
        if (!this.props.weekDisplay) {
            resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
            this.setState({ selectionRange: resetSelectionRange });
            if (this.props.onChange) this.props.onChange(resetSelectionRange.startDate, resetSelectionRange.endDate);
        } else {
            resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
            this.setState({ selectionRange: resetSelectionRange });
            if (this.props.onReset) this.props.onReset(resetSelectionRange.startDate, resetSelectionRange.endDate);
        }
    };

    handleResetSelection = () => {
        let resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
        this.setState({ selectionRange: resetSelectionRange, show: false });
        document.removeEventListener('click', this.handleGlobalClick);
    };

    handleSaveBtnClick = () => {
        if (this.props.onSaveClicked) this.props.onSaveClicked();
        this.setState({ show: false });
    };

    combineYearLabel = (startDate, endDate, formattedStart, formattedEnd) => {
        if (startDate === null || endDate === null) {
            return formattedStart + ':' + formattedEnd;
        }
        if (startDate.getFullYear() === endDate.getFullYear()) {
            return (
                months[startDate.getMonth()] +
                ' ' +
                startDate.getDate() +
                ' : ' +
                months[endDate.getMonth()] +
                ' ' +
                endDate.getDate() +
                ', ' +
                startDate.getFullYear()
            );
        }
    };

    closeDialog = () => {
        this.setState({ show: false });
    };

    handleReset = () => {
        let resetSelectionRange;
        if (!this.props.weekDisplay) {
            resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
            this.setState({ selectionRange: resetSelectionRange });
            if (this.props.onChange) this.props.onChange(resetSelectionRange.startDate, resetSelectionRange.endDate);
        } else {
            resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
            this.setState({ selectionRange: resetSelectionRange });
            if (this.props.onReset) this.props.onReset(resetSelectionRange.startDate, resetSelectionRange.endDate);
        }
    };

    render() {
        const { startDate, endDate } = this.state.selectionRange;
        const todaysFormattedStartDate = startDate ? DuDateUtilities.ToString(startDate) : '';
        const todaysFormattedEndDate = endDate ? DuDateUtilities.ToString(endDate) : '';
        const {
            label,
            placeholder,
            variant,
            helperText,
            width,
            style,
            InputProps,
            InputLabelProps,
            classes,
            onClose,
            required,
            months,
            direction,
            isDateRange,
            combineLabelYears,
            weekDisplay,
            defaultValue,
            minDate,
            ...other
        } = this.props;

        return (
            <div>
                <div>
                    <DrcInput
                        className={classes.root}
                        style={{ width: width || '100%', ...style }}
                        fullWidth={true}
                        margin="dense"
                        label={
                            required ? (
                                <span>
                                    {label || ''} <span className={classes.labelError}>*</span>
                                </span>
                            ) : (
                                label || ''
                            )
                        }
                        isDateRange={isDateRange !== undefined ? isDateRange : true}
                        placeholder={placeholder}
                        defaultValue="Default Value"
                        value={
                            !defaultValue
                                ? combineLabelYears
                                    ? this.combineYearLabel(startDate, endDate, todaysFormattedStartDate, todaysFormattedEndDate)
                                    : `${todaysFormattedStartDate} : ${todaysFormattedEndDate}`
                                : ''
                        }
                        onClick={this.handleClick}
                        onClose={onClose}
                        InputProps={{
                            ...InputProps
                        }}
                        InputLabelProps={{
                            ...InputLabelProps
                        }}
                        helperText={helperText || ''}
                        error={(helperText || '').length > 0}
                        autoComplete="off"
                        {...other}
                    />
                </div>
                <DrcDialog
                    title="Select Pool Week Range"
                    open={this.state.show}
                    className={classes.dialogWrapper}
                    buttons={
                        <>
                            <DrcButton isPrimary onClick={this.closeDialog}>
                                <DrcTranslate>{'Close'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={() => this.handleReset()}>
                                <DrcTranslate>{'Reset'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <DateRange
                        months={months}
                        direction={direction}
                        editableDateInputs={true}
                        onChange={this.handleChange}
                        moveRangeOnFirstSelection={false}
                        ranges={[this.state.selectionRange]}
                        startDatePlaceholder={'Start Date'}
                        endDatePlaceholder={'End Date'}
                        dateDisplayFormat={'MMM-d-yyyy'}
                        minDate={minDate}
                    />
                </DrcDialog>
            </div>
        );
    }
}

DrcDateRangePicker.defaultProps = {
    direction: 'horizontal',
    months: 2
};

export default withStyles(styles)(DrcDateRangePicker);
