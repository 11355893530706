/** SDD:
 * List of Data
 * In the data list page, we will show all files that have been uploaded and allow the user to filter based on template, filename, status, created by and modified by.
 * If the user is allowed there will be an option to edit or delete the data, otherwise only reading data will be allowed.
 * Here the user will have actions to view the data, edit the data (if allowed), and view the change log.
 */

import { DrcButton, DrcDataGrid, DrcDialog, DrcPanel, DrcSelect, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities, DuDateUtilities, DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import { withStyles } from '@material-ui/core/styles';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import UpdateIcon from '@material-ui/icons/Update';
import { withOktaAuth } from '@okta/okta-react';
import { format, isValid } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hideLoadingScreenAction, setPageTitleAction, showLoadingScreenAction, showToast } from '../../../actions/actions';
import { setImportedFileData } from '../../../actions/importFileActions';
import { ReactComponent as Download } from '../../../assets/Download.svg';
import { ReactComponent as DownloadTemplate } from '../../../assets/DownloadTemplate.svg';
import { ReactComponent as Import } from '../../../assets/Import.svg';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import Filter from '../../../components/filter/Filter';
import ImportExcel from '../../../components/import/ExcelImport';
import validate from '../../../components/import/validate';
import {
    boolColumns,
    dateFields,
    getUserNameColumns,
    middlewareConfig,
    numericColumns,
    dateColumns,
    templateColToDbColMap,
    templateColumnsMap
} from '../../../data/constants';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { uploadToS3 } from '../../../data/s3Upload';
import GridStyles from '../../../styles/gridStyles';
import { formatApiResponse, makeColumns } from '../../../utils/helper';
import EditDialog from '../Dialogs/EditDialog';
import ChartView from '../Dialogs/ChartView';
import GraphDataTemplate from '../Dialogs/GraphDataTemplate';
import APIEndPoints from '../services/api';
import { productionSystemValues } from '../../../constants';

const PAGE_TITLE = 'Data Overview';

const styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    main: {
        minHeight: 'calc(100vh - 200px)',
        display: 'flex',
        justifyContent: 'center'
    },
    header: {
        borderBottom: 'none',
        marginTop: '0px',
        padding: '0px'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    panel: {
        width: '100vw',
        maxWidth: 'calc(100vw - 100px) !important',
        border: 'none',
        padding: 0,
        margin: 0
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    icon: {
        width: '24px',
        height: '24px',
        fill: theme.palette.primary.main,
        marginRight: '4px',
        marginTop: '-2px'
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    }
});

const metadata = [
    {
        tabName: 'Trait Data',
        tabIndex: 1,
        fields: {
            marketCondition: {
                dataType: 'decimal',
                maxValue: 2147483647,
                required: false,
                displayName: 'Market Condition'
            },
            reversion: {
                dataType: 'decimal',
                maxValue: 2147483647,
                required: false,
                displayName: 'Reversion'
            },
            brix: {
                dataType: 'decimal',
                required: false,
                displayName: 'Brix',
                measurement: 'Degree',
                precision: 3
            },
            flavorScore: {
                dataType: 'decimal',
                required: false,
                displayName: 'Flavor Score',
                precision: 3
            },
            plantYield: {
                dataType: 'decimal',
                required: false,
                displayName: 'Plant Yield',
                measurement: 'Kg/Plant',
                precision: 3
            },
            marketableYield: {
                dataType: 'decimal',
                required: false,
                displayName: 'Marketable Yield',
                measurement: 'Kg/Ha',
                precision: 3
            },
            fruitSize: {
                dataType: 'decimal',
                required: false,
                displayName: 'Fruit Size',
                measurement: 'g/berry',
                precision: 3
            },
            cullRate: {
                dataType: 'decimal',
                required: false,
                displayName: 'Cull Rate',
                measurement: '% bad',
                precision: 3
            },
            firmness: {
                dataType: 'decimal',
                required: false,
                displayName: 'Firmness',
                measurement: 'g/mm',
                precision: 3
            },
            diameter: {
                dataType: 'decimal',
                maxValue: 2147483647,
                required: false,
                displayName: 'Diameter',
                measurement: 'mm'
            }
        }
    },
    {
        tabName: 'Master Data',
        tabIndex: 0,
        fields: {
            selection: {
                dataType: 'text',
                required: true,
                readOnly: true,
                displayName: 'Selection'
            },
            fieldCode: {
                dataType: 'text',
                required: true,
                readOnly: true,
                displayName: 'Field code'
            },

            varietyName: {
                dataType: 'text',
                required: false,
                displayName: 'Variety Name',
                max: 100
            },
            location: {
                dataType: 'text',
                required: true,
                readOnly: true,
                displayName: 'Location'
            },
            spines: {
                dataType: 'bool',
                required: false,
                displayName: 'Spines'
            }
        }
    },
    {
        tabName: 'Field Context',
        tabIndex: 2,
        fields: {
            productionSystem: {
                dataType: 'select',
                required: false,
                displayName: 'Production System',
                options: productionSystemValues.map((option) => {
                    return { value: option.trim().toUpperCase(), label: option };
                })
            },
            plantingDate: {
                dataType: 'dateTime',
                readOnly: true,
                required: true,
                displayName: 'Planting Date'
            },
            harvestWeekBeg: {
                dataType: 'dateTime',
                required: false,
                displayName: 'Harvest Week Beg'
            },
            harvestWeekEnd: {
                dataType: 'dateTime',
                required: false,
                displayName: 'Harvest Week End'
            },
            plantAge: {
                dataType: 'number',
                maxValue: 2147483647,
                required: false,
                displayName: 'Plant Age',
                max: 4
            },
            cycle: {
                dataType: 'text',
                required: true,
                readOnly: true,
                displayName: 'Cycle',
                max: 100
            }
        }
    },
    {
        tabName: 'Business Context',
        tabIndex: 3,
        fields: {
            businessSolution: {
                dataType: 'text',
                required: false,
                displayName: 'Business Solution',
                max: 1000
            },
            businessUnit: {
                dataType: 'select',
                required: false,
                displayName: 'Business Unit',
                options: [
                    { value: 'DOTA', label: 'DOTA' },
                    { value: 'DEMEA', label: 'DEMEA' },
                    { value: 'DOC', label: 'DOC' },
                    { value: 'DANZ', label: 'DANZ' }
                ]
            },
            challenges: {
                dataType: 'text',
                required: false,
                displayName: 'Challenges',
                max: 1000
            },
            commercializedProjection: {
                dataType: 'number',
                maxValue: 2147483647,
                required: false,
                displayName: 'Commercialized Projection',
                max: 4
            },
            crossMade: {
                dataType: 'number',
                maxValue: 2147483647,
                required: false,
                displayName: 'Cross Made',
                max: 4
            },
            description: {
                dataType: 'text',
                required: false,
                displayName: 'Description',
                max: 1000
            },
            gains: {
                dataType: 'text',
                required: false,
                displayName: 'Gains',
                max: 1000
            },
            oftYear: {
                dataType: 'number',
                maxValue: 2147483647,
                required: false,
                displayName: 'OFT Year'
            },
            stage: {
                dataType: 'text',
                // maxValue: 2147483647,
                required: false,
                displayName: 'Stage',
                max: 11
            },
            proprietary: {
                dataType: 'bool',
                required: false,
                displayName: 'Proprietary'
            },
            breederNote: {
                dataType: 'text',
                required: false,
                displayName: 'Breeder Note',
                max: 1000
            },
            contactPerson: {
                dataType: 'text',
                required: false,
                displayName: 'Contact Person',
                max: 100
            }
        }
    }
];

class DataHome extends React.Component {
    state = {
        summaryData: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        showUploadDialog: false,
        isFileValid: false,
        showStatusDialog: false,
        showEditDialog: false,
        showGraphViewDialog: false,
        showGraphDataTemplateOptions: false,
        showFilter: false,
        rowData: null,
        editDialogTittle: '',
        graphViewTitle: '',
        showDeleteDialog: false,
        deleteableSelection: { Id: '', rowData: {} },
        missingHeaders: [],
        showHeaderDialog: false,
        showError: false
    };
    graphViewEnabled = true;
    excludingFields = ['Actions', 'Id'];
    readOnly = false;
    uniqueKeyField = 'Id';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;
    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.loadSummaryData();
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.berry !== this.props.berry) {
            this.applyFilter();
            this.setState({ selectedRow: [] });
        }

        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
    }

    formatRowsData = (data) => formatApiResponse(data, dateFields, boolColumns, false);

    loadSummaryData = async () => {
        this.showMessage('Loading data', 'Hold on tight while we load the data.');
        let token = await this.props.oktaAuth.getAccessToken();
        let filter = this.getAppliedFilters();
        try {
            Middleware.Send(
                '',
                token,
                APIEndPoints.GET_SUMMARY_DATA(
                    this.state.pageNum / this.state.pageSize + 1,
                    this.state.pageSize,
                    this.props.berry,
                    filter['Selection'],
                    filter['Status'],
                    filter['Cycle'],
                    filter['Location'],
                    filter['FieldCode']
                ),
                'GET',
                {},
                { ...middlewareConfig(true, true), allowEmptyValues: true }
            )
                .then((response) => {
                    this.response = response;
                    this.updateDataTable(null, true);
                    this.hideMessage();
                })
                .catch((error) => {
                    this.hideMessage();
                    console.log({ error });
                });
        } catch (error) {
            this.hideMessage();
            console.log(error);
        }
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let summaryData = response.TotalCount === 0 ? [] : this.formatRowsData(JSON.parse(JSON.stringify(response.Data || [])));
        let columns = summaryData.length
            ? makeColumns(Object.keys(summaryData[0]), this.excludingFields, this.getActionsCell, !this.readOnly, hasFrozenColumns)
            : {};
        this.setState({
            summaryData,
            totalCount: response.TotalCount,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadSummaryData();
    };

    getRowCount = () => {
        if (this.state.totalCount < this.state.pageSize) {
            return this.state.totalCount;
        }

        return this.state.pageSize;
    };

    showGraphVew = (_event, data) => {
        let title = `Selection: ${data.selection}`;
        if (data.fieldCode) {
            title = `${title}, Field Code : ${data.fieldCode}`;
        }
        if (data.cycle) {
            title = `${title}, Cycle : ${data.cycle}`;
        }
        if (data.plantingDate) {
            title = `${title}, Planting Date : ${data.plantingDate}`;
        }
        this.setState({ showGraphViewDialog: true, rowData: data, graphViewTitle: title });
    };

    editFile = (_event, data) => {
        let title = `Selection: ${data.selection}`;
        if (data.fieldCode) {
            title = `${title}, Field Code: ${data.fieldCode}`;
        }
        if (data.cycle) {
            title = `${title}, Cycle: ${data.cycle}`;
        }
        if (data.plantingDate) {
            title = `${title}, Planting Date: ${data.plantingDate}`;
        }
        this.setState({ showEditDialog: true, rowData: data, editDialogTittle: title });
    };
    cancelEdit = (_event, _rowData) => {
        //todo
    };
    cannotEdit = (row) => (row.status || '').toLowerCase() === 'deleted' || (row.status || '').toLowerCase() === 'approved';
    deleteData = async (rowData) => {
        this.setState({ showDeleteDialog: true, deleteableSelection: { Id: rowData.Id, rowData: rowData } });
    };
    confirmEdit = async (_event, _rowData) => {
        //todo
    };
    cannotDelete = (row) => (row.status || '').toLowerCase() === 'deleted';

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={this.editMode === 'inline'}
                uniqueKeyField={'Id'}
                objState={this}
                records={this.state.summaryData}
                initRowEdit={this.editFile}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                showGraphVew={this.showGraphVew}
                row={row}
                rowUnderEditUniqueKeyValue={row['Id']}
            />
        );
    };

    handleRowSelection = (e) => {
        const totalOfRecords = this.state.summaryData.filter((row) => (row.status || '').toLowerCase() !== 'deleted').length;
        const totalOfRecordsSelected = e.value.length;
        const selectAll = totalOfRecords === totalOfRecordsSelected;
        if (e.value && !e.value.filter((row) => (row.status || '').toLowerCase() === 'deleted').length)
            this.setState({ selectedRow: e.value, selectAll });
    };

    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState((prevState) => {
                return {
                    selectAll: true,
                    selectedRow: prevState.summaryData.filter((row) => (row.status || '').toLowerCase() !== 'deleted')
                };
            });
        } else {
            this.setState({
                selectAll: false,
                selectedRow: []
            });
        }
    };

    openUploadDialog = () => {
        this.setState({ showUploadDialog: true, graphDataUpload: false });
    };

    openGraphDataUploadDialog = () => {
        this.setState({ showUploadDialog: true, graphDataUpload: true });
    };

    handleUpdate = async () => {
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let resp = await Middleware.Send(
                '',
                token,
                APIEndPoints.bulkUpdate,
                'POST',
                {
                    SummaryData: this.state.selectedRow.map((itm) => itm.summaryDataId),
                    Status: this.state.newStatus,
                    LastUpdatedBy: loggedInUser,
                    LastUpdatedDateTime: new Date().toISOString()
                },
                middlewareConfig(true)
            );
            if (resp?.Message === 'Request processed successfully') {
                this.props.showToast('Status Updated', 'success');
                this.setState({ showStatusDialog: false, selectedRow: [], newStatus: null });
                this.loadSummaryData();
            } else {
                this.props.showToast('Unable to update', 'error');
            }
        } catch (err) {
            this.props.showToast('Unable to update', 'error');
            console.log(err);
        }
    };

    coloumnSelection = (selectedBerry) => {
        let exc = ['Status', 'Created By', 'Created', 'Updated By', 'Updated', 'spines', 'reversion', 'pipelineTiming'];
        if (selectedBerry === 'BLUE') {
            return [...exc, 'Spines', 'Reversion', 'Pipeline Harvest Efficiency'];
        } else if (selectedBerry === 'BLACK') {
            return [...exc, 'Plant Yield', 'Diameter', 'Firmness', 'Plant Age', 'Fruit Size', 'Brix', 'Pipeline Harvest Efficiency'];
        } else if (selectedBerry === 'RASP') {
            return [...exc, 'Plant Yield', 'Diameter', 'Firmness', 'Plant Age', 'Fruit Size', 'Spines', 'Reversion', 'Pipeline Timing'];
        } else if (selectedBerry === 'STRAW') {
            return [...exc, 'Plant Yield', 'Diameter', 'Firmness', 'Plant Age', 'Fruit Size', 'Spines', 'Reversion', 'Pipeline Timing'];
        } else return exc;
    };

    getTemplateColumns = () => {
        let excludedColumns = [];
        excludedColumns = ['Status', 'Created By', 'Created', 'Updated By', 'Updated'];

        let templateColumns = Object.assign({}, templateColumnsMap);
        templateColumns.brix = templateColumns.brix + ' (Degree)';
        templateColumns.plantYield = templateColumns.plantYield + ' (Kg/plant)';
        templateColumns.marketableYield = templateColumns.marketableYield + ' (Kg/Ha)';
        templateColumns.fruitSize = templateColumns.fruitSize + ' (g/berry)';
        templateColumns.cullRate = templateColumns.cullRate + ' (% bad)';
        templateColumns.firmness = templateColumns.firmness + ' (g/mm)';
        templateColumns.diameter = templateColumns.diameter + ' (mm)';

        return Object.values(templateColumns)
            .filter((col) => !excludedColumns.includes(col))
            .map((key) => ({ name: key, key }));
    };

    getDownloadColumns = () => {
        let excludedColumns = [];
        return Object.values(templateColumnsMap)
            .filter((col) => !excludedColumns.includes(col))
            .map((key) => ({ name: key, key }));
    };

    generateTemplate = () => {
        DuExcelUtilities.Write('template.xlsx', this.getTemplateColumns(), []);
    };

    /**
     * Method that fetches all data for the current filters and downloads them in a excel spreadsheet
     * @async
     * @function downloadData
     */
    downloadData = async () => {
        // show downloading message
        this.showMessage('Preparing download', 'Your download should start in a few seconds.');

        // prepare parameters
        let token = await this.props.oktaAuth.getAccessToken();
        let filter = this.getAppliedFilters();

        // call API to fetch all the data
        await Middleware.Send(
            '',
            token,
            APIEndPoints.FETCH_SUMMARY_DATA(
                this.props.berry,
                filter['Selection'],
                filter['Status'],
                filter['Cycle'],
                filter['Location'],
                filter['FieldCode']
            ),
            'GET',
            {},
            { ...middlewareConfig(true, true), allowEmptyValues: true }
        )
            .then((response) => {
                // convert data for spreadheet
                let summaryData = this.formatRowsData(JSON.parse(JSON.stringify(response.Data)));
                let formattedData = summaryData.map((row) => {
                    const keyValues = Object.keys(row).map((key) => {
                        return { [templateColumnsMap[key]]: row[key] };
                    });
                    return Object.assign({}, ...keyValues);
                });

                //create the Spreadsheet
                DuExcelUtilities.Write('summaryData.xlsx', this.getDownloadColumns(), formattedData);
            })
            .catch((error) => {
                console.log(error);
                this.props.showToast('Unable to create download', 'error');
            });
        // hide the downloading message
        this.hideMessage();
    };

    handleDelete = async () => {
        try {
            // hide delete-modal
            this.setState({ showDeleteDialog: false });
            this.showMessage('Processing delete', 'Deleting');

            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let resp = await Middleware.Send(
                '',
                token,
                APIEndPoints.bulkUpdate,
                'POST',
                {
                    SummaryData: [this.state.deleteableSelection.Id],
                    Status: 'Deleted',
                    LastUpdatedBy: loggedInUser,
                    LastUpdatedDateTime: new Date().toISOString()
                },
                middlewareConfig(true)
            );
            this.setState({ deleteableSelection: { Id: '', rowData: {} } });
            if (resp?.Message === 'Request processed successfully') {
                this.props.showToast('Deleted', 'success');
                this.hideMessage();
                this.loadSummaryData();
            } else {
                this.hideMessage();
                this.props.showToast('Unable to delete', 'error');
            }
        } catch (err) {
            this.hideMessage();
            this.props.showToast('Unable to delete', 'error');
            console.log(err);
        }
    };

    storeEditedData = async (editedRow) => {
        try {
            let editedRowWithDbCols = Object.keys(editedRow).reduce((result, key) => {
                let field = key === 'summaryDataId' || key === 'Id' ? 'summaryDataId' : key;
                let value = editedRow[key];
                let dbField = this.props.uiToApiMap[field];
                return {
                    ...result,
                    [dbField]: numericColumns.includes(dbField)
                        ? value
                            ? Number(value)
                            : null
                        : dateColumns.includes(dbField)
                        ? isValid(new Date(value))
                            ? format(new Date(value), 'yyyy-MM-dd')
                            : null
                        : value
                };
            }, {});

            this.showMessage('Processing update', 'Updating');

            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let resp = await Middleware.Send(
                '',
                token,
                APIEndPoints.editSummaryData,
                'POST',
                {
                    ...editedRowWithDbCols,
                    LastUpdatedBy: loggedInUser,

                    LastUpdatedDateTime: new Date().toISOString()
                },
                middlewareConfig(true)
            );
            this.hideMessage();
            this.loadSummaryData();
        } catch (err) {
            this.hideMessage();
            this.props.showToast('An error occurred', 'error');
            console.log(err);
        }
    };

    getUserNameColumnValues = async () => {
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            //'CreatedBy', 'CreatedDatetime', 'ModifiedBy', 'ModifiedDateTime'
            return [loggedInUser, new Date().toISOString(), loggedInUser, new Date().toISOString()];
        } catch (err) {
            console.error(err);
        }
    };

    renameKeys = (row) => {
        if ('Brix (Degree)' in row) {
            row['Brix'] = row['Brix (Degree)'];
            delete row['Brix (Degree)'];
        }
        if ('Cull Rate (% bad)' in row) {
            row['Cull Rate'] = row['Cull Rate (% bad)'];
            delete row['Cull Rate (% bad)'];
        }
        if ('Diameter (mm)' in row) {
            row['Diameter'] = row['Diameter (mm)'];
            delete row['Diameter (mm)'];
        }
        if ('Firmness (g/mm)' in row) {
            row['Firmness'] = row['Firmness (g/mm)'];
            delete row['Firmness (g/mm)'];
        }
        if ('Fruit Size (g/berry)' in row) {
            row['Fruit Size'] = row['Fruit Size (g/berry)'];
            delete row['Fruit Size (g/berry)'];
        }
        if ('Marketable Yield (Kg/Ha)' in row) {
            row['Marketable Yield'] = row['Marketable Yield (Kg/Ha)'];
            delete row['Marketable Yield (Kg/Ha)'];
        }
        if ('Plant Yield (Kg/plant)' in row) {
            row['Plant Yield'] = row['Plant Yield (Kg/plant)'];
            delete row['Plant Yield (Kg/plant)'];
        }
        return row;
    };

    getDateFromExcelTimeStamp = (excelDateTimeValue) => {
        if (!excelDateTimeValue) {
            return '';
        }

        const jsDateTimeStamp = new Date(Math.round((excelDateTimeValue - 25569) * 86400 * 1000));
        //removing timezone offset to get exact date
        jsDateTimeStamp.setMinutes(jsDateTimeStamp.getMinutes() + jsDateTimeStamp.getTimezoneOffset());
        return DuDateUtilities.ToIsoDate(jsDateTimeStamp, '').split('T')[0];
    };

    getISOFromExcelDateString = (dateString) => {
        let dateParts = dateString.split('-');
        let d;
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        if (months.indexOf(dateParts[0].toLowerCase()) > -1) {
            //Sep-23-2021
            let month = months.indexOf(dateParts[0].toLowerCase());
            d = new Date(+dateParts[2], month, dateParts[1]);
        } else {
            d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        }
        return DuDateUtilities.IsValidDate(d) ? DuDateUtilities.ToIsoDate(d, false, 'N/A') : dateString;
    };

    cleanUpUploadColumns = async (data = [], skipKeys) => {
        try {
            let fileData = [];
            let userNameFieldsValue = await this.getUserNameColumnValues();
            const dateCols = ['Harvest Week Beg', 'Harvest Week End', 'Planting Date'];
            const boolCols = ['Proprietary', 'Spines'];
            const decimalFields = ['Brix', 'Cull Rate', 'Firmness', 'Flavor Score', 'Fruit Size', 'Marketable Yield', 'Plant Yield'];
            let poolWeekFields = [];
            Object.keys(this.props.fileData[0]).map((key) => {
                if (!isNaN(key)) {
                    poolWeekFields.push(key);
                }
                return;
            });
            data.forEach((row) => {
                row = this.renameKeys(row);
                let columnData = [];
                Object.keys(row).forEach((field) => {
                    if (!skipKeys.includes(field)) {
                        if (typeof row[field] === 'string') {
                            row[field] = row[field].trim();
                        }
                        if (dateCols.includes(field)) {
                            let excelToJsDate = this.getDateFromExcelTimeStamp(row[field]);
                            if (excelToJsDate === 'N/A') {
                                if (typeof row[field] === 'string') {
                                    row[field] = this.getISOFromExcelDateString(row[field]);
                                } else {
                                    row[field] = null;
                                }
                            } else if (excelToJsDate !== 'N/A') {
                                row[field] = excelToJsDate;
                            }
                        }
                        if (boolCols.includes(field)) {
                            row[field] = this.checkAndConvertBoolValue(row[field]);
                        }
                        if (decimalFields.includes(field)) {
                            row[field] = this.isEmpty(row[field]) ? '' : Math.round(row[field] * 1000) / 1000;
                        }
                        if (poolWeekFields.includes(field)) {
                            row[field] = row[field] ? row[field] : 0;
                        }
                        columnData.push(row[field]);
                    }
                });
                fileData.push([...columnData, ...userNameFieldsValue]);
            });
            return fileData;
        } catch (err) {
            console.error(err);
        }
    };

    isInt = (value) => !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
    isEmpty = (data) => data === null || data === undefined || data === '';

    checkAndConvertBoolValue = (value) => {
        let result = value;
        if (!this.isEmpty(value)) {
            if (typeof value === 'boolean') {
                result = value ? 1 : 0;
            } else if (this.isInt(value)) {
                result = parseInt(Number(value));
            } else {
                result = this.checkString(value);
            }
        }
        return result;
    };

    checkString = (value) => {
        return ['0', 'false', 'no'].includes(value.toLowerCase()) ? 0 : ['1', 'true', 'yes'].includes(value.toLowerCase()) ? 1 : value;
    };

    arrayToCSV = (array) => {
        let str = '';
        return array.reduce((_str, next) => {
            str += `${Object.values(next)
                .map((value) => `${value === null ? '' : value}`)
                .join('|')}\r\n`;
            return str;
        }, str);
    };

    handleGraphDataUpload = async () => {
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            let userid = DuAuthenticationUtilities.GetUserId(token);
            let uploadFileNameForApi = `${new Date().toISOString().replace(':', '-').replace(':', '-')}.csv`;
            let uploadFileName = `graph_${userid}_${uploadFileNameForApi}`;

            let { url, name } = await Middleware.Send('', token, APIEndPoints.GET_S3_CREDENTIALS(uploadFileName), 'GET', {}, middlewareConfig(true));

            if (!url || !name) {
                this.hideMessage();
                this.props.showToast('An error occurred', 'error');
                return;
            }

            this.showMessage('Uploading File', 'Performing Step 2 of 2');

            let skipKeys = [];
            let cleanedData = (await this.cleanUpUploadColumns(this.props.fileData, skipKeys)) || [];
            let fileData = this.arrayToCSV(cleanedData);
            Object.keys(this.props.fileData[0]).map((key) => {
                if (!isNaN(key)) {
                    templateColToDbColMap[key] = key;
                } else if (key === 'Graph Type') {
                    templateColToDbColMap[key] = 'GraphType';
                }
                return;
            });
            // changing csv columns headers to db column names
            fileData = `${[
                ...Object.keys(this.props.fileData[0] || {}).reduce((acc, key) => {
                    let colName = templateColToDbColMap[(key || '').trim()];
                    if (colName) acc.push(colName);
                    return acc;
                }, []),
                ...getUserNameColumns()
            ]
                .filter((e) => !skipKeys.includes(e))
                .join('|')}\r\n${fileData}`;

            let response = await uploadToS3(url, fileData);

            this.handleImportClose();
            this.props.showToast('Filed Uploaded', 'success');
            this.hideMessage();
        } catch (err) {
            console.error(err);
            this.handleImportClose();
            this.props.showToast('An error occurred', 'error');
            this.hideMessage();
        }
    };

    handleDataUpload = async () => {
        this.showMessage('Starting Upload', 'Performing Step 1 of 2');
        if (this.state.graphDataUpload) {
            this.handleGraphDataUpload();
            return;
        }
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            let userid = DuAuthenticationUtilities.GetUserId(token);
            let uploadFileNameForApi = `${new Date().toISOString().replace(':', '-').replace(':', '-')}.csv`;
            let uploadFileName = `${userid}_${uploadFileNameForApi}`;

            let { url, name } = await Middleware.Send('', token, APIEndPoints.GET_S3_CREDENTIALS(uploadFileName), 'GET', {}, middlewareConfig(true));

            if (!url || !name) {
                this.hideMessage();
                this.props.showToast('An error occurred', 'error');
                return;
            }

            this.showMessage('Uploading File', 'Performing Step 2 of 2');

            let skipKeys = [];
            let cleanedData = (await this.cleanUpUploadColumns(this.props.fileData, skipKeys)) || [];
            let fileData = this.arrayToCSV(cleanedData);

            // changing csv columns headers to db column names
            fileData = `${[
                ...Object.keys(this.props.fileData[0] || {}).reduce((acc, key) => {
                    let colName = templateColToDbColMap[(key || '').trim()];
                    if (colName) acc.push(colName);
                    return acc;
                }, []),
                ...getUserNameColumns()
            ]
                .filter((e) => !skipKeys.includes(e))
                .join('|')}\r\n${fileData}`;

            let response = await uploadToS3(url, fileData);

            this.handleImportClose();
            this.props.showToast('Filed Uploaded', 'success');
            this.hideMessage();
        } catch (err) {
            console.error(err);
            this.handleImportClose();
            this.props.showToast('An error occurred', 'error');
            this.hideMessage();
        }
    };

    handleError = () => {
        let cols = Object.keys(this.props.fileData[0]).map((key) => {
            return { name: key, key: key };
        });
        cols.push({ name: 'Errors', key: 'Errors' });
        let data = this.props.fileData.map((row, index) => ({ ...row, Errors: this.state.errors[index].join(',') }));
        DuExcelUtilities.Write('errors.xlsx', cols, data);
    };

    handleImportClose = () => {
        this.setState({ showHeaderDialog: false, showError: false, showUploadDialog: false, missingHeaders: [], isFileValid: false });
    };

    handleParsedFile = ({ data, fileName }) => {
        if (this.state.graphDataUpload) {
            this.handleGraphDataParsing({ data, fileName });
            return;
        }

        this.showMessage('Validating File', 'It can take a few seconds to a couple of minutes based on the file size');

        try {
            if (!data.length) {
                this.hideMessage();
                this.setState({ isFileValid: false });
                return;
            }

            this.props.setImportedFileData(data);
            this.hideMessage();

            const errors = validate(data);
            this.hideMessage();
            const isFileValid = !errors.length;
            const showError = !isFileValid;
            !isFileValid && this.props.showToast('Invalid File', 'error');
            this.setState({ invalidHeader: false, showHeaderDialog: false, isFileValid, showError, errors });
            // }
        } catch (err) {
            console.error(err);
        }
    };

    handleGraphDataParsing = ({ data, _fileName }) => {
        this.showMessage('Validating File', 'It can take a few seconds to a couple of minutes based on the file size');
        try {
            if (!data.length) {
                this.hideMessage();
                this.setState({ isFileValid: false });
                return;
            }

            this.props.setImportedFileData(data);
            this.hideMessage();
            const errors = validate(data);
            this.hideMessage();
            const isFileValid = !errors.length;
            const showError = !isFileValid;
            !isFileValid && this.props.showToast('Invalid File', 'error');
            this.setState({ invalidHeader: false, showHeaderDialog: false, isFileValid, showError, errors });
            // }
        } catch (err) {
            console.error(err);
        }
    };

    getStatusOptions = () => {
        let options = ['Approved', 'Deleted', 'Draft'];
        return options.map((optn) => ({ label: optn, value: optn }));
    };

    /**
     * Shows a message/loadingscreen to the user.
     * Make sure the translations for the text exist in appTranslations.
     * @function showMessage
     * @param {string} messageTitle - The title to display in the messageBox
     * @param {string} message - The message to display in the messageBox.
     * @fires showLoadingScreenAction
     */
    showMessage = (messageTitle, message) => {
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>
                    <DrcTranslate>{messageTitle}</DrcTranslate>
                </h4>
                <div>
                    <DrcTranslate>{message}</DrcTranslate>
                </div>
            </React.Fragment>
        );
    };

    /**
     * Closes any message/loadingscreen opened by {@link showMessage} for the user.
     * @function hideMessage
     * @fires hideLoadingScreenAction
     */
    hideMessage = () => {
        this.props.hideLoadingScreenAction();
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });
    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadSummaryData());
    };
    getAppliedFilters = () =>
        this.props.filters.reduce((filter, row) => {
            if (row.hasOptions || row.isAsync) {
                filter[row.fieldName] = row.selected?.value;
            } else if (row.isAlphanumeric) {
                filter[row.fieldName] = row.value;
            }
            return filter;
        }, {});

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadSummaryData());
    };

    rowClassName = (rowData) => {
        if ((rowData.status || '').toLowerCase() === 'deleted') return { checkboxDisabled: true };
    };

    handleEditSave = (editedRow) => {
        const editedSummaryData = [...this.state.summaryData];
        const index = editedSummaryData.findIndex((sd) => sd.summaryDataId === editedRow.summaryDataId);
        if (!editedRow.spines || editedRow.spines === 'No') {
            editedRow.spines = false;
        } else {
            editedRow.spines = true;
        }
        if (!editedRow.proprietary || editedRow.proprietary === 'No') {
            editedRow.proprietary = false;
        } else {
            editedRow.proprietary = true;
        }
        this.storeEditedData(editedRow);
        editedSummaryData[index] = { ...editedSummaryData[index], ...editedRow };
        this.setState({ summaryData: editedSummaryData });
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <DrcPanel className={classes.panel}>
                    <div className={classes.filterContainer}>
                        <Filter showFilter={this.state.showFilter} applyFilter={this.applyFilter} resetFilter={this.resetFilter} />
                    </div>
                    <div className={classes.pageActions}>
                        <div>
                            <DrcButton onClick={this.toggleShowFilter}>
                                <FilterListSharp className={classes.icon} />
                                <DrcTranslate>{'Filter'}</DrcTranslate>
                            </DrcButton>
                        </div>
                        <div>
                            <>
                                {!!this.state.selectedRow.length && (
                                    <DrcButton onClick={() => this.setState({ showStatusDialog: true })}>
                                        <UpdateIcon className={classes.icon} />
                                        <DrcTranslate>{'Update'}</DrcTranslate>
                                    </DrcButton>
                                )}
                                {!!this.state.selectedRow.length && this.graphViewEnabled && (
                                    <DrcButton onClick={() => this.setState({ showGraphDataTemplateOptions: true })}>
                                        <DownloadTemplate className={classes.icon} />
                                        <DrcTranslate>{'DownloadGraphDataTemplate'}</DrcTranslate>
                                    </DrcButton>
                                )}
                            </>
                            <DrcButton onClick={this.generateTemplate}>
                                <DownloadTemplate className={classes.icon} />
                                <DrcTranslate>{'DownloadTemplate'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={this.downloadData}>
                                <Download className={classes.icon} />
                                <DrcTranslate>{'ExportData'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={this.openUploadDialog}>
                                <Import className={classes.icon} />
                                <DrcTranslate>{'ImportData'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={this.openGraphDataUploadDialog}>
                                <Import className={classes.icon} />
                                <DrcTranslate>{'ImportGraphData'}</DrcTranslate>
                            </DrcButton>
                        </div>
                    </div>
                    {this.state.summaryData.length ? (
                        <DrcDataGrid
                            paginator={true}
                            rowSelect={true}
                            selectionMode="multiple"
                            currentPage={this.state.pageNum}
                            selected={this.state.selectedRow}
                            selectedFrozen={true}
                            selectedClass={classes.selectedClass}
                            selectionChange={this.handleRowSelection}
                            onPage={this.onPage}
                            uniqueKeyField={'Id'}
                            columns={this.state.columns}
                            rows={this.state.summaryData}
                            gridStyles={classes.gridStyles}
                            totalRecords={this.state.totalCount}
                            resultCount={this.state.totalCount}
                            pageSize={this.state.pageSize}
                            rowsToLoadPerScroll={this.getRowCount()}
                            lazy={true}
                            virtualRowHeight={48}
                            rowUnderEditUniqueKey={this.state.rowUnderEditUniqueKey}
                            onEditorValueChange={this.onEditorValueChange}
                            showReport={true}
                            currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                            rowClassName={this.rowClassName}
                            tableSelectionMode="checkbox"
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.onSelectAllChange}
                        />
                    ) : (
                        <DrcTranslate>{'No records to display'}</DrcTranslate>
                    )}
                </DrcPanel>
                {this.state.showUploadDialog && (
                    <ImportExcel
                        title={<DrcTranslate>{'ImportData'}</DrcTranslate>}
                        showUploadDialog={this.state.showUploadDialog}
                        showHeaderDialog={false}
                        missingHeaders={[]}
                        showError={this.state.showError}
                        closeHeaderDialog={() => this.setState({ showHeaderDialog: false })}
                        openHeaderDialog={() => this.setState({ showHeaderDialog: true })}
                        handleImportClose={this.handleImportClose}
                        handleDataUpload={this.handleDataUpload}
                        handleParsedFile={this.handleParsedFile}
                        handleError={this.handleError}
                        isFileValid={this.state.isFileValid}
                    />
                )}
                <DrcDialog
                    title={<DrcTranslate>{'Summary Data Status Update'}</DrcTranslate>}
                    open={this.state.showStatusDialog}
                    buttons={
                        <>
                            <DrcButton isPrimary onClick={this.handleUpdate} disabled={!this.state.newStatus}>
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={() => this.setState({ showStatusDialog: false, selectedRow: [], newStatus: null })}>
                                <DrcTranslate>{'Oops Just Kidding'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <DrcSelect
                        required
                        options={this.getStatusOptions()}
                        onChange={(option) => this.setState({ newStatus: option.label })}
                        label={<DrcTranslate>{'Select new status'}</DrcTranslate>}
                    />
                </DrcDialog>
                <DrcDialog
                    title={<DrcTranslate>{'Delete selection?'}</DrcTranslate>}
                    open={this.state.showDeleteDialog}
                    buttons={
                        <>
                            <DrcButton isPrimary onClick={this.handleDelete}>
                                <DrcTranslate>{'Delete'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={() => this.setState({ showDeleteDialog: false, deleteableSelection: { Id: '', rowData: {} } })}>
                                <DrcTranslate>{'Oops Just Kidding'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <DrcTranslate>{'Are you sure you want to delete this selection?'}</DrcTranslate>
                    <br />
                    <strong>
                        <DrcTranslate>{'Selection'}</DrcTranslate>:
                    </strong>
                    {this.state.deleteableSelection?.rowData.selection}
                    <br />
                </DrcDialog>
                <EditDialog
                    id="summaryDataId"
                    title={this.state.editDialogTittle}
                    open={this.state.showEditDialog}
                    closeDialog={() => this.setState({ showEditDialog: false })}
                    rowData={this.state.rowData}
                    metadata={metadata}
                    save={(editedRow) => this.handleEditSave(editedRow)}
                />
                {this.state.showGraphViewDialog ? (
                    <ChartView
                        id="summaryDataId"
                        title={this.state.graphViewTitle}
                        open={this.state.showGraphViewDialog}
                        closeDialog={() => this.setState({ showGraphViewDialog: false })}
                        rowData={this.state.rowData}
                        metadata={metadata}
                    />
                ) : null}
                {this.state.showGraphDataTemplateOptions ? (
                    <GraphDataTemplate
                        id="summaryDataId"
                        title={'Download Template'}
                        open={this.state.showGraphDataTemplateOptions}
                        closeDialog={() => this.setState({ showGraphDataTemplateOptions: false })}
                        selectedRows={this.state.selectedRow}
                        metadata={metadata}
                    />
                ) : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        filters: state.summaryReducer.filters,
        uiToApiMap: state.summaryReducer.uiToApiMap,
        berry: state.masterReducer.berry,
        fileData: state.importFileReducer.fileData,
        currentLanguage: state.locale
    };
}

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setImportedFileData: (fileData) => dispatch(setImportedFileData(fileData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(withStyles(styles)(DataHome))));
