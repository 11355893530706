import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DrcMain, DrcTabs, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import DataHome from '../apps/Data/pages/DataHome';
import LogHome from '../apps/Log/pages/LogHome';

export default function Home() {
    const useStyles = makeStyles(() => ({
        main: {
            maxWidth: '96vw !important',
            padding: '8px 24px',
            borderRadius: '4px'
        }
    }));
    const allGroups = window.config.OKTA_ADMIN_GROUPS || [];

    const classes = useStyles();
    return (
        <DrcMain className={classes.main}>
            <DrcTabs
                menuItems={[
                    {
                        tabName: <DrcTranslate>{'Summary'}</DrcTranslate>,
                        tabIndex: 0,
                        component: DataHome,
                        route: 'Summary',
                        isDefault: true,
                        groupsAllowed: allGroups
                    },
                    {
                        tabName: <DrcTranslate>{'Log'}</DrcTranslate>,
                        tabIndex: 1,
                        component: LogHome,
                        route: 'Log',
                        groupsAllowed: allGroups
                    }
                    // {
                    //     tabName: 'Template',
                    //     tabIndex: 2,
                    //     component: TemplateHome,
                    //     route: `Template`,
                    //     groupsAllowed: allGroups
                    // }
                ]}
                registerRoutes
            />
        </DrcMain>
    );
}
