class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static GET_LOG_DATA = (startPage = 0, pageSize = 0, berryType, selection, status, cycle, location, fieldCode) => {
        let params = '';
        if (berryType) {
            params = `${params}&berrytype=${berryType}`;
        }
        if (selection) {
            params = `${params}&selection=${selection}`;
        }
        if (status) {
            params = `${params}&status=${status}`;
        }
        if (cycle) {
            params = `${params}&cycle=${cycle}`;
        }
        if (location) {
            params = `${params}&location=${location}`;
        }
        if (fieldCode) {
            params = `${params}&fieldcode=${fieldCode}`;
        }
        return `${this.BASE}logdata?startpage=${startPage}&pagesize=${pageSize}${params}`;
    };
}

export default APIEndPoints;
