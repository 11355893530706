import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const styles = (theme) => ({
    dataTableCell: {
        fontSize: 13,
        backgroundColor: theme.light.warning,
        [theme.darkTheme]: theme.dark.warning
    }
});

class DataChangedFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { row, field, classes } = this.props;
        const value = row[field];

        return <span className={classes.dataTableCell}>{value}</span>;
    }
}

export default withStyles(styles)(DataChangedFormatter);
