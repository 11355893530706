import { Middleware } from '@driscollsinc/one-ring';
import summaryData from '../json/summaryData';

const summaryDataFunc = { ...Middleware.CreateHandler('GET', Middleware.NoOp) };

function ConfigureFakeData() {
    if (!Middleware.CheckRegisteredFakeDataHandlers('SummaryData')) {
        Middleware.RegisterFakeDataHandler('SummaryData', summaryData, summaryDataFunc);
    }
}

const summaryDataFake = {
    ConfigureFakeData
};

export default summaryDataFake;
