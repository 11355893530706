import { DrcButton, DrcDialog, DrcInput, DrcTooltip, DrcTranslate, DrcSelect } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { withOktaAuth } from '@okta/okta-react';
import cloneDeep from 'lodash/cloneDeep';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { connect } from 'react-redux';
import { hideLoadingScreenAction, showLoadingScreenAction, showToast } from '../../../actions/actions';
import LabelFormatter from '../../../components/cellFormatters/LabelFormatter';
import Grid from '@material-ui/core/Grid';
import { middlewareConfig } from '../../../data/constants';
import GridStyles from '../../../styles/gridStyles';
import APIEndPoints from '../services/api';

const GraphTypes = [
    { label: 'Diameter', value: 'Diameter', berries: ['BLUE'] },
    { label: 'Flavor', value: 'Flavor', berries: ['BLUE', 'STRAW', 'RASP', 'BLACK'] },
    { label: 'Yield (Kg/Ha)', value: 'Yield', berries: ['BLUE', 'STRAW', 'RASP', 'BLACK'] },
    { label: 'Fruit Size', value: 'Fruit Size', berries: ['STRAW'] },
    { label: 'Brix', value: 'Brix', berries: ['BLUE', 'STRAW', 'RASP', 'BLACK'] }
];

const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            overflow: 'hidden',
            maxWidth: '900px !important', //TO override dialog maxWidth
            minHeight: '300px',
            maxHeight: '70vh'
        }
    },
    asyncItem: {
        height: '45px',
        width: '100%',
        alignSelf: 'center',
        marginTop: '1px'
    },
    deleteButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: '#e34843',
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: '#ec110a'
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: '#ec110a',
                backgroundColor: '#0000'
            }
        }
    }
});

const changeValue = (stateRef, _key, props, value) => {
    let updatedValues = [...props.value];
    const oldValue = updatedValues[props.rowIndex][props.field];
    const apply = (callback) => {
        updatedValues[props.rowIndex][props.field] = value;
        stateRef.setState({ rows: updatedValues }, callback);
    };
    const undo = (callback) => {
        updatedValues = [...props.value];
        updatedValues[props.rowIndex][props.field] = oldValue;
        stateRef.setState({ rows: updatedValues }, callback);
    };
    return { apply, undo };
};

const deleteRow = (stateRef, row, rows) => {
    const oldValue = cloneDeep(rows);
    const apply = (callback) => {
        row.op = 'delete';
        stateRef.setState({ rows: rows }, callback);
    };
    const undo = (callback) => {
        stateRef.setState({ rows: oldValue }, callback);
    };

    return { apply, undo };
};

const addRow = (stateRef, rows, newValue) => {
    const oldValue = cloneDeep(rows);
    const apply = (callback) => {
        let updated = cloneDeep(rows);
        updated.push(newValue);
        stateRef.setState({ rows: updated }, callback);
    };
    const undo = (callback) => {
        stateRef.setState({ rows: oldValue }, callback);
    };

    return { apply, undo };
};

class ChartView extends React.Component {
    state = {
        rows: [],
        columns: [],
        showDeleteDialog: false,
        saveEnabled: false,
        graphType: GraphTypes.filter((e) => e.berries.includes(this.props.berry))[0]
    };

    undoStack = [];
    redoStack = [];
    changedData = [];
    deletingItem = null;

    getActionsCell = (row) => {
        return (
            <div>
                <DrcButton
                    size="small"
                    isSecondary
                    noStyle
                    className={this.props.classes.deleteButton}
                    title="Delete"
                    onClick={() => this.deleteItem(row)}
                >
                    <DeleteIcon />
                </DrcButton>
            </div>
        );
    };

    deleteItem = (row) => {
        this.deletingItem = row;
        this.setState({ showDeleteDialog: true });
    };

    labelTemplate = (name, dataType) =>
        function labelComp(row) {
            return <LabelFormatter row={row} field={name} dataType={dataType} />;
        };

    getColumnTemplate = (key, dataType) => {
        return this.labelTemplate(key, dataType);
    };

    makeColumns = (data = [], excludingFields = [], actionsCell, hasActions = false, hasFrozenColumns = true) => {
        let frozenCol = hasFrozenColumns ? ['selection', 'fieldCode', 'cycle', 'plantingDate', 'status'] : [];
        const templateColumnsMap = {
            WeekNumber: 'Week Number',
            Value: 'Value'
        };
        let columns = hasActions
            ? [
                  {
                      key: 'Actions',
                      name: <DrcTranslate>{'Actions'}</DrcTranslate>,
                      columnTemplate: actionsCell,
                      width: '8%',
                      editable: false
                  }
              ]
            : [];
        let responseColumns = typeof data[0] === 'object' ? Object.keys(data[0]) : ['WeekNumber', 'Value'];
        let columnNames = Object.keys(templateColumnsMap).filter((col) => responseColumns.includes(col));
        columns = [
            ...columns,
            ...columnNames
                .map((key) => {
                    if (Array.isArray(excludingFields) && !excludingFields.includes(key)) {
                        let editable = false;
                        let dataType = 'text';
                        let required = false;
                        let selectOptions = [];
                        return {
                            width: '42%',
                            key,
                            name: <DrcTranslate>{templateColumnsMap[key]}</DrcTranslate>,
                            columnTemplate: this.getColumnTemplate(key, dataType),
                            editable: editable,
                            editorType: 'text',
                            required: required,
                            editorOptions: selectOptions,
                            ...{ ...(frozenCol.includes(key) ? { frozen: true } : {}) }
                        };
                    }
                    return null;
                })
                .filter((e) => e)
        ];
        return columns;
    };

    componentDidMount = async () => {
        this.getGraphData();
        document.addEventListener('keydown', this.keyHandler);
    };

    getGraphData = async () => {
        let token = await this.props.oktaAuth.getAccessToken();
        let selection = this.props.rowData;
        let response = await Middleware.Send(
            '',
            token,
            APIEndPoints.GET_GRAPH_DATA(selection.summaryDataId, this.state.graphType.value),
            'GET',
            {},
            { ...middlewareConfig(true, true), allowEmptyValues: true }
        );

        this.actualResponse = cloneDeep(response);
        let cols = this.makeColumns(response, [], this.getActionsCell, true, false);
        this.setState({
            columns: cols,
            rows: [
                ...response.sort((item1, item2) => {
                    return item1.WeekNumber - item2.WeekNumber;
                })
            ]
        });
    };

    keyHandler = (event) => {
        let reDo = event.key === 'y';
        if (event.ctrlKey && (event.key === 'z' || reDo)) {
            let op = reDo ? this.redoStack.pop() : this.undoStack.pop();
            if (op && !reDo) {
                this.redoStack.push(op);
                op.undo(() => {
                    this.validateData();
                });
            } else if (op && reDo) {
                this.undoStack.push(op);
                op.apply(() => {
                    this.validateData();
                });
            }
        }
    };

    validateData = () => {
        let rows = this.state.rows;
        let changedValues = rows.filter((item) => {
            let oItem = this.actualResponse.find((item2) => item2.GraphDataId === item.GraphDataId);
            return !oItem || oItem.Value !== item.Value || oItem.WeekNumber !== item.WeekNumber || oItem.op !== item.op;
        });
        console.log(changedValues);
        this.changedData = changedValues;
        this.setState({ saveEnabled: this.changedData.length > 0 });
    };

    onEditorValueChange = (key, props, value) => {
        let op = changeValue(this, key, props, value);
        this.undoStack.push(op);
        op.apply(() => {
            this.validateData();
        });
    };

    rowClassName = (rowData) => {
        if ((rowData.status || '').toLowerCase() === 'deleted') return { checkboxDisabled: true };
    };

    inputTextEditor(key, props, field) {
        return (
            <DrcInput
                className={this.props.classes.input}
                onChange={(e) => this.onEditorValueChange(key, props, e.target.value)}
                value={props.rowData[field]}
            />
        );
    }

    nameEditor(key, props) {
        return this.inputTextEditor(key, props, key);
    }

    cancelDelete = () => {
        this.deletingItem = null;
        this.setState({ showDeleteDialog: false });
    };

    handleDelete = () => {
        let op = deleteRow(this, this.deletingItem, this.state.rows);
        this.undoStack.push(op);
        op.apply(() => {
            this.setState({ showDeleteDialog: false });
            this.validateData();
        });
    };

    handleAddItem = () => {
        let newValue = { WeekNumber: 0, Value: 0, GraphDataId: (this.state.rows.length + 1) * -1 };
        let op = addRow(this, this.state.rows, newValue);
        this.undoStack.push(op);
        op.apply(() => {
            this.validateData();
        });
    };

    handleOnSave = async () => {
        let token = await this.props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        let now = new Date().toISOString();
        let payload = this.changedData.map((item) => {
            if (item.GraphDataId < 0) {
                delete item.GraphDataId;
            }
            return {
                ...item,
                SummaryDataId: this.props.rowData.summaryDataId,
                GraphName: this.state.graphType.value,
                CreatedDateTime: item.CreatedDateTime ? item.CreatedDateTime : now,
                CreatedBy: item.CreatedBy ? item.CreatedBy : loggedInUser,
                UpdatedDateTime: item.UpdatedDateTime ? item.UpdatedDateTime : now,
                UpdatedBy: item.UpdatedBy ? item.UpdatedBy : loggedInUser
            };
        });
        try {
            this.showMessage('Saving data...', 'Saving data...');
            let response = await Middleware.Send('', token, APIEndPoints.POST_GRAPH_DATA, 'PUT', payload, {
                ...middlewareConfig(false, true),
                allowEmptyValues: true
            });
            this.props.showToast(response.Message, 'success');
        } catch (error) {
            this.props.showToast('An error occurred', 'error');
        } finally {
            this.changedData = [];
            this.setState({ saveEnabled: this.changedData.length > 0 });
            this.hideMessage();
            this.props.closeDialog();
        }
    };

    handleGraphTypeChange = (option) => {
        this.setState({ graphType: option });
        this.getGraphData();
    };

    showMessage = (messageTitle, message = '') => {
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>
                    <DrcTranslate>{messageTitle}</DrcTranslate>
                </h4>
                <div>
                    <DrcTranslate>{message}</DrcTranslate>
                </div>
            </React.Fragment>
        );
    };

    hideMessage = () => {
        this.props.hideLoadingScreenAction();
    };

    render() {
        const { title, classes } = this.props;
        const { saveEnabled, graphType } = this.state;
        return (
            <>
                <DrcDialog
                    fullActionWrapper
                    title={title || ''}
                    className={classes.dialogWrapper}
                    open={this.props.open}
                    buttons={
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <DrcButton isPrimary onClick={this.handleAddItem}>
                                    <DrcTranslate>{'AddRecord'}</DrcTranslate>
                                </DrcButton>
                            </div>
                            <div className="col-xs-12 col-sm-6" style={{ float: 'right' }}>
                                <DrcButton isPrimary onClick={this.handleOnSave} disabled={!saveEnabled}>
                                    <DrcTranslate>{'Save'}</DrcTranslate>
                                </DrcButton>
                                <DrcButton
                                    onClick={() => {
                                        this.props.closeDialog();
                                    }}
                                >
                                    <DrcTranslate>{'Close'}</DrcTranslate>
                                </DrcButton>
                            </div>
                        </div>
                    }
                >
                    <>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <DrcSelect
                                options={GraphTypes.filter((e) => e.berries.includes(this.props.berry))}
                                label={<DrcTranslate>{'Graph Types'}</DrcTranslate>}
                                value={graphType}
                                onChange={(option) => this.handleGraphTypeChange(option)}
                            />
                        </Grid>
                        <div className={this.props.classes.gridStyles} style={{ minHeight: '200px', maxHeight: '300px', minWidth: '868px' }}>
                            <DataTable value={this.state.rows.filter((row) => row.op !== 'delete')} editMode="cell" frozenWidth={'40px'}>
                                {this.state.columns.map((column) => {
                                    return column.key === 'WeekNumber1' ? (
                                        <Column
                                            editable={false}
                                            style={{ width: column.width, textAlign: 'center' }}
                                            key={column.key}
                                            field={column.key}
                                            header={
                                                <DrcTooltip tipText={column.name}>
                                                    <span>{column.shortName || column.name}</span>
                                                </DrcTooltip>
                                            }
                                            body={column.columnTemplate || null}
                                        ></Column>
                                    ) : (
                                        <Column
                                            editable={true}
                                            style={{ width: column.width, textAlign: 'center' }}
                                            key={column.key}
                                            field={column.key}
                                            header={
                                                <DrcTooltip tipText={column.name}>
                                                    <span>{column.shortName || column.name}</span>
                                                </DrcTooltip>
                                            }
                                            body={column.columnTemplate || null}
                                            editor={(props) =>
                                                column.key !== 'Actions' ? this.nameEditor(column.key, props) : this.getActionsCell(props.rowData)
                                            }
                                        ></Column>
                                    );
                                })}
                            </DataTable>
                        </div>
                    </>
                </DrcDialog>

                <DrcDialog
                    title="Delete Record?"
                    open={this.state.showDeleteDialog}
                    buttons={
                        <>
                            <DrcButton isPrimary onClick={this.handleDelete}>
                                <DrcTranslate>{'Delete'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={() => this.cancelDelete()}>
                                <DrcTranslate>{'Oops Just Kidding'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <DrcTranslate>{'Are you sure you want to delete this record?'}</DrcTranslate>
                    <br />
                    {this.state.deleteableSelection?.rowData.selection}
                    <br />
                </DrcDialog>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        berry: state.masterReducer.berry
    };
}

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(ChartView)));
