// [English, Spanish, Chinese, French]

const APP_TRANSLATIONS = {
    Summary: ['Summary', 'Resumen', '概括', 'Résumé'],
    Log: ['Log', 'Registro', '日志', 'Journal'],
    Filter: ['Filter', 'Filtros', '筛选', 'Filtre'],
    Update: ['Update', 'Subir', '更新', 'Mettre à jour'],
    DownloadTemplate: ['Download Template', 'Descargar Plantilla', '下载模板', 'Télécharger le modèle'],
    DownloadGraphDataTemplate: [
        'Download Graph Data Template',
        'Descargar Plantilla',
        '下载图表数据模板',
        'Télécharger le modèle de données de graphique'
    ],
    ImportData: ['Import Data', 'Importar Datos', '导入数据', 'Importer des données'],
    ImportGraphData: ['Import Graph Data', 'Importar Datos', '导入图形数据', 'Importer des données de graphique'],
    ExportData: ['Export Data to Excel', 'Exportar Datos', '导出数据', 'Exporter des données'],
    Selection: ['Selection', 'Selección'],
    'Field Code': ['Field Code', 'Código de Campo'],
    Cycle: ['Cycle', 'Ciclo'],
    'Planting Date': ['Planting Date', 'Fecha de Plantación'],
    'Variety Name': ['Variety Name', 'Nombre de Variedad'],
    Location: ['Location', 'Localización'],
    Stage: ['Stage', 'Etapa'],
    'Production System': ['Production System', 'Sistema de Producción'],
    'Harvest Week Beg': ['Harvest Week Beg', 'Semana de Inicio de Cocecha'],
    'Harvest Week End': ['Harvest Week End', 'Semana de Fin de Cocecha'],
    'Market Condition': ['Market Condition', 'Condición de Mercado'],
    Brix: ['Brix'],
    'Flavor Score': ['Flavor Score', 'Puntuaje de Sabor'],
    'Plant Yield': ['Plant Yield', 'Rendimiento de Planta'],
    'Cull Rate': ['Cull Rate', 'Taza de Desecho'],
    'Marketable Yield': ['Marketable Yield', 'Rendimiento Comercializable'],
    'Fruit Size': ['Fruit Size', 'Tamaño de Fruta'],
    'Business Solution': ['Business Solution', 'Solución de Negocio'],
    'Business Unit': ['Business Unit', 'Unidad de Negocio'],
    Challenges: ['Challenges', 'Retos'],
    'Commercialized Projection': ['Commercialized Projection', 'Proyección Comercializada'],
    'Cross Made': ['Cross Made', 'Cruzado'],
    Description: ['Description', 'Descripción'],
    Firmness: ['Firmness', 'Firmesa'],
    Gains: ['Gains', 'Ganancias'],
    'OFT Year': ['OFT Year', 'Año OFT'],
    Reversion: ['Reversion', 'Reversion'],
    Spines: ['Spines', 'Espinas'],
    Diameter: ['Diameter', 'Diametro'],
    'Plant Age': ['Plant Age', 'Edad de la planta'],
    Proprietary: ['Proprietary', 'Propietario'],
    'Most Recent Data Update': ['Most Recent Data Update', 'Actualización mas Reciente'],
    'Breeder Note': ['Breeder Note', 'Nota de Mejorador de Plantas'],
    'Contact Person': ['Contact Person', 'Contacto'],
    Status: ['Status', 'Estado'],
    Created: ['Created', 'Creado'],
    'Created By': ['Created By', 'Creado Por'],
    Updated: ['Updated', 'Actualizado'],
    'Updated By': ['Updated By', 'Actualizado Por'],
    'Are you sure you want to delete this selection?': [
        'Are you sure you want to delete this selection?',
        '¿Estas seguro de eliminar esta selección?'
    ],
    'Oops Just Kidding': ['Oops Just Kidding', 'Ups Solo Bromeo'],
    Delete: ['delete', 'eliminar'],
    Actions: ['Actions', 'Acciones'],
    'Apply Filter': ['Apply Filter', 'Aplicar Filtro'],
    'Reset Filter': ['Reset Filter', 'Reiniciar Filtro'],
    Save: ['Save', 'Guardar'],
    Close: ['Close', 'Cerrar'],
    'Sign Out': ['Sign Out', 'Cerrar Sesión'],
    'Hold on tight while we load the data.': ['Hold on tight while we load the data.', 'Espera mientras cargamos los datos'],
    'Loading data': ['Loading data', 'Cargando los Datos'],
    'Preparing download': ['Preparing download', 'Preparando descarga'],
    'Your download should start in a few seconds.': ['Your download should start in a few seconds.'],
    'No records to display': ['No records to display', 'No hay registros para mostrar'],
    'Choose Excel': ['Choose Excel', 'Escoger Excel'],
    'Drop Excel': ['Drop Excel', 'Remover Excel'],
    Submit: ['Submit', 'Enviar'],
    Errors: ['Errors', 'Errores'],
    'Stop Processing': ['Stop Processing', 'Detener procesamiento'],
    Continue: ['Continue', 'Continuar'],
    'Missing Headers': ['Missing Headers', 'Encabezados faltantes'],
    'Header Missing Msg': ['Header Missing Msg', 'Mensaje de Encabezado faltante'],
    'Select new status': ['Select new status', 'Escoge un nuevo estatus'],
    'Delete selection?': ['Delete selection?', '¿Eliminar selección?'],
    'Summary Data Status Update': ['Summary Data Status Update', 'Estatus de carga del resumen de datos'],
    'Missing Columns': ['Missing Columns', 'Columnas Faltantes'],
    'Showing {first} to {last} of {totalRecords} entries': [
        'Showing {first} to {last} of {totalRecords} entries',
        'Mostrando {first} a {last} de {totalRecords} registros'
    ],
    'Brix (Degree)': ['Brix (Degree)', 'Brix (Grado)'],
    'Plant Yield (Kg/Plant)': ['Plant Yield (Kg/Plant)', 'Rendimiento de Planta (Kg/Planta)'],
    'Marketable Yield (Kg/Ha)': ['Marketable Yield (Kg/Ha)', 'Rendimiento Comercializable (Kg/Ha)'],
    'Fruit Size (g/berry)': ['Fruit Size (g/berry)', 'Tamaño de Fruta (g/berry)'],
    'Cull Rate (% bad)': ['Cull Rate (% bad)', 'Taza de desecho (% malo)'],
    'Firmness (g/mm)': ['Firmness (g/mm)', 'Firmesa (g/mm)'],
    'Diameter (mm)': ['Diameter (mm)', 'Diametro (mm)'],
    AddRecord: ['Add New Item', 'Agregar ítem nuevo']
};

export default APP_TRANSLATIONS;
