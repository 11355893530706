import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    label: {
        color: '#eee',
        alignSelf: 'center',
        padding: '4px',
        width: '6rem',
        textAlign: 'center',
        fontFamily: 'rubik',
        borderRadius: '4px',
        letterSpacing: '0.5px'
    },
    approved: {
        background: theme.palette.success.dark
    },
    deleted: {
        background: theme.palette.error.main
    },
    draft: {
        background: theme.palette.info.main
    }
});

function DisplayStatus({ classes, val }) {
    const isApproved = (val || '').toLowerCase() === 'approved';
    const isDeleted = (val || '').toLowerCase() === 'deleted' || (val || '').toLowerCase() === 'discarded';
    const appliedClass = isApproved ? classes.approved : isDeleted ? classes.deleted : classes.draft;
    return (
        <div className={classes.container}>
            {isApproved ? (
                <label className={`${classes.label} ${appliedClass}`}>{val}</label>
            ) : isDeleted ? (
                <label className={`${classes.label} ${appliedClass}`}>{val}</label>
            ) : (
                <label className={`${classes.label} ${appliedClass}`}>Draft</label>
            )}
        </div>
    );
}

export default withStyles(styles)(DisplayStatus);
