import { SET_FILTER, SET_SUMMARY_DATA, CLEAR_SUMMARY_DATA, SET_FILTERED_SUMMARY_DATA, SET_UI_TO_API_KEY_MAP } from '../actions/SummaryAction';

const initialState = {
    records: [],
    filters: [],
    totalRecords: 0,
    uiToApiMap: {}
};

const SummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUMMARY_DATA:
            let records = [];
            records = action.payload.records;

            return Object.assign({}, state, {
                records,
                totalRecords: action.payload.count
            });
        case CLEAR_SUMMARY_DATA:
            return Object.assign({}, state, {
                records: [],
                totalRecords: 0
            });
        case SET_FILTERED_SUMMARY_DATA:
            return Object.assign({}, state, {
                records: action.payload.records,
                totalRecords: action.payload.count
            });

        case SET_FILTER:
            return Object.assign({}, state, {
                filters: action.payload
            });

        case SET_UI_TO_API_KEY_MAP:
            return Object.assign({}, state, {
                uiToApiMap: action.payload
            });

        default:
            return state;
    }
};

export default SummaryReducer;
