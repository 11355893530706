import { SET_IMPORTED_FILE_DATA } from '../actions/importFileActions';

const initialState = {
    fileData: []
};

const importFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IMPORTED_FILE_DATA:
            return Object.assign({}, state, {
                fileData: action.payload
            });
        default:
            return state;
    }
};

export default importFileReducer;
