import React from 'react';
import LabelFormatter from '../components/cellFormatters/LabelFormatter';
import DisplayBool from '../components/cellFormatters/DisplayBool';
import DisplayStatus from '../components/cellFormatters/DisplayStatus';
import DataChangedFormatter from '../components/cellFormatters/DataChangedFormatter';
import MasterDataValidation from '../data/MasterDataValidation';
import { boolColumns, dateTimeFields, editableFields, statusColumns, templateColumnsMap } from '../data/constants';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { camelCase } from 'lodash';
import { Store } from '../data/store';
import { setUiToApiMap } from '../actions/SummaryAction';

let originalLog = {};
const labelTemplate = (name, dataType) =>
    function labelComp(row) {
        return <LabelFormatter row={row} field={name} dataType={dataType} />;
    };

const boolCellTemplate = (name) =>
    function boolComp(row) {
        if (row[name] !== '') {
            return <DisplayBool val={row[name] === 'Yes'} />;
        }
    };

const statusCellTemplate = (name) =>
    function statusComp(row) {
        return <DisplayStatus val={row[name]} />;
    };

const dataChangeIndicatorTemplate = (name, dataType) =>
    function diffComp(row) {
        let formatter = <LabelFormatter row={row} field={name} dataType={dataType} />;
        if (editableFields.indexOf(name) > -1 && row.changes && row.changes.indexOf(name.toLowerCase()) > -1) {
            formatter = <DataChangedFormatter row={row} field={name} dataType={dataType} />;
        }
        return formatter;
    };

const getColumnTemplate = (key, dataType) => {
    if (boolColumns.includes(key) || dataType === 'bool') {
        return boolCellTemplate(key);
    } else if (statusColumns.includes(key)) {
        return statusCellTemplate(key);
    } else if (editableFields.includes(key)) {
        return dataChangeIndicatorTemplate(key, dataType);
    } else return labelTemplate(key, dataType);
};

export const makeColumns = (data = [], excludingFields = [], actionsCell, hasActions = false, hasFrozenColumns = true) => {
    let frozenCol = hasFrozenColumns ? ['selection', 'fieldCode', 'cycle', 'plantingDate', 'status'] : [];
    let columns = hasActions
        ? [{ key: 'Actions', name: <DrcTranslate>{'Actions'}</DrcTranslate>, columnTemplate: actionsCell, frozen: true, width: '60px' }]
        : [];
    let responseColumns = typeof data[0] === 'object' ? Object.keys(data[0]) : data;
    let columnNames = Object.keys(templateColumnsMap).filter((col) => responseColumns.includes(col));
    columns = [
        ...columns,
        ...columnNames
            .map((key) => {
                if (Array.isArray(excludingFields) && !excludingFields.includes(key)) {
                    let editable = false;
                    let dataType = 'text';
                    let required = false;
                    let selectOptions = [];
                    return {
                        width: 100,
                        key,
                        name: <DrcTranslate>{templateColumnsMap[key]}</DrcTranslate>,
                        columnTemplate: getColumnTemplate(key, dataType),
                        editable: editable,
                        editorType: MasterDataValidation.getEditorType(dataType, key),
                        required: required,
                        editorOptions: selectOptions,
                        ...{ ...(frozenCol.includes(key) ? { frozen: true } : {}) }
                    };
                }
                return null;
            })
            .filter((e) => e)
    ];
    return columns;
};

export const formatApiResponse = (rowData, dateKeys = [], booleanKeys = [], addActionColumn = false, overrideBoolean = false) => {
    const rows = toCamel(rowData); //TODO Remove when the endpoints return camelCases responses
    let apiRowFormat = rowData;
    if (Array.isArray(rowData)) {
        apiRowFormat = rowData[0] || {};
    }
    let uiToApiMap = Object.keys(apiRowFormat).reduce(
        (result, key) => ({
            ...result,
            [camelCase(key)]: key
        }),
        {}
    );
    Store.dispatch(setUiToApiMap(uiToApiMap));
    return rows.map((row) => {
        dateKeys.map((key) => {
            if (row[key])
                return (row[key] = dateTimeFields.includes(key)
                    ? DuDateUtilities.FormatDateTimeFromIso(row[key])
                    : DuDateUtilities.FormatDateFromIso(row[key]));
            return null;
        });
        booleanKeys.map((key) => {
            return !overrideBoolean ? (row[key] = row[key] === null ? '' : [true, 1, '1', 'Yes', 'YES'].includes(row[key]) ? 'Yes' : 'No') : row[key];
        });
        if (addActionColumn) {
            row.Actions = 'Actions';
        }
        row.Id = row.summaryDataId;
        return row;
    });
};

//Use only if the endpoints return no lower properties
const toCamel = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => toCamel(v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: toCamel(obj[key])
            }),
            {}
        );
    }
    return obj;
};
