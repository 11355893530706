export const boolColumns = ['proprietary', 'spines'];

//For now the order of columns is also important. Need to rethink on this
export const editableFields = [
    'brix',
    'businessSolution',
    'businessUnit',
    'challenges',
    'commercializedProjection',
    'crossMade',
    'cullRate',
    'description',
    'firmness',
    'flavorScore',
    'fruitSize',
    'gains',
    'harvestWeekBeg',
    'harvestWeekEnd',
    'marketCondition',
    'reversion',
    'plotId',
    'fieldCode',
    'oftYear',
    'stage',
    'productionSystem',
    'marketableYield',
    'plantYield',
    'location',
    'spines',
    'varietyName',
    'diameter',
    'plantAge',
    'breederNote',
    'contactPerson'
];

export const statusColumns = ['status'];

export const dateFields = [
    'lastUpdatedDateTime',
    'createdDateTime',
    'dateOfMostRecentDataUpdate',
    'plantingDate',
    'harvestWeekBeg',
    'harvestWeekEnd'
];
export const dateTimeFields = ['lastUpdatedDateTime', 'createdDateTime', 'dateOfMostRecentDataUpdate'];

export const templateColumnsMap = {
    selection: 'Selection',
    fieldCode: 'Field Code',
    cycle: 'Cycle',
    plantingDate: 'Planting Date',
    status: 'Status',
    varietyName: 'Variety Name',
    location: 'Location',
    stage: 'Stage',
    productionSystem: 'Production System',
    harvestWeekBeg: 'Harvest Week Beg',
    harvestWeekEnd: 'Harvest Week End',
    marketCondition: 'Market Condition',
    reversion: 'Reversion',
    brix: 'Brix',
    flavorScore: 'Flavor Score',
    plantYield: 'Plant Yield',
    cullRate: 'Cull Rate',
    marketableYield: 'Marketable Yield',
    fruitSize: 'Fruit Size',
    businessSolution: 'Business Solution',
    businessUnit: 'Business Unit',
    challenges: 'Challenges',
    commercializedProjection: 'Commercialized Projection',
    crossMade: 'Cross Made',
    description: 'Description',
    firmness: 'Firmness',
    gains: 'Gains',
    // harvestWeeks: 'Harvest Weeks',
    // plotId: 'Plot ID',
    oftYear: 'OFT Year',
    spines: 'Spines',
    diameter: 'Diameter',
    plantAge: 'Plant Age',
    proprietary: 'Proprietary',
    // dateOfMostRecentDataUpdate: 'Most Recent Data Update',
    breederNote: 'Breeder Note',
    contactPerson: 'Contact Person',
    createdDateTime: 'Created',
    createdBy: 'Created By',
    lastUpdatedDateTime: 'Updated',
    lastUpdatedBy: 'Updated By'
};

export const filterFields = [
    {
        FieldName: 'Selection',
        DisplayName: 'Selection',
        Datatype: 'string',
        ColumnName: 'Selection',
        IsSearchable: true,
        IsAsync: true
    },
    {
        FieldName: 'Status',
        DisplayName: 'Status',
        Datatype: 'string',
        ColumnName: 'Status',
        IsSearchable: true,
        hasOptions: true,
        Options: [
            { label: 'Approved', Code: 'approved' },
            { label: 'Deleted', Code: 'deleted' },
            { label: 'Draft', Code: 'draft' }
        ]
    },
    {
        FieldName: 'Cycle',
        DisplayName: 'Cycle',
        Datatype: 'alphanumeric',
        ColumnName: 'Cycle',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'Location',
        DisplayName: 'Location',
        Datatype: 'alphanumeric',
        ColumnName: 'Location',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'FieldCode',
        DisplayName: 'Field Code',
        Datatype: 'alphanumeric',
        ColumnName: 'FieldCode',
        IsSearchable: true,
        IsAsync: false
    }
];

export const middlewareNoLoaderConfig = {
    showLoadingScreen: () => {
        /*todo*/
    },
    hideLoadingScreen: () => {
        /*todo*/
    }
};

export const middlewareNoMappingConfig = {
    overrideRequestMapping: true,
    overrideResponseMapping: true
};

export const middlewareNoMappingAndLoaderConfig = {
    ...middlewareNoLoaderConfig,
    ...middlewareNoMappingConfig
};

export const berryMap = { 1: 'BLACK', 2: 'BLUE', 3: 'RASP', 4: 'STRAW' };

/**
 *
 * @param {Boolean} noloader
 * @param {Boolean} noMapping
 * @param {String} loadingMessage
 */
export const middlewareConfig = (noloader, noMapping, loadingMessage) => {
    let config = {};
    if (noloader) {
        config = { ...config, ...middlewareNoLoaderConfig };
    }

    if (noMapping) {
        config = { ...config, ...middlewareNoMappingConfig };
    }

    if (loadingMessage) {
        config = { ...config, loadingMessage };
    }

    return config;
};

export const templateColToDbColMap = {
    Selection: 'Selection',
    'Field Code': 'FieldCode',
    Cycle: 'Cycle',
    'Planting Date': 'PlantingDate',
    'Variety Name': 'VarietyName',
    Location: 'Location',
    Stage: 'Stage',
    'Production System': 'ProductionSystem',
    'Harvest Week Beg': 'HarvestWeekBeg',
    'Harvest Week End': 'HarvestWeekEnd',
    'Market Condition': 'MarketCondition',
    Reversion: 'Reversion',
    Brix: 'Brix',
    'Flavor Score': 'FlavorScore',
    'Plant Yield': 'PlantYield',
    'Cull Rate': 'CullRate',
    'Marketable Yield': 'MarketableYield',
    'Fruit Size': 'FruitSize',
    'Business Solution': 'BusinessSolution',
    'Business Unit': 'BusinessUnit',
    Challenges: 'Challenges',
    'Commercialized Projection': 'CommercializedProjection',
    'Cross Made': 'CrossMade',
    Description: 'Description',
    Firmness: 'Firmness',
    Gains: 'Gains',
    // 'Harvest Weeks': '',
    // 'Plot ID': '',
    'OFT Year': 'OFTYear',
    Spines: 'Spines',
    Diameter: 'Diameter',
    'Plant Age': 'PlantAge',
    Proprietary: 'Proprietary',
    // 'Most Recent Data Update': 'DateofMostRecentDataUpdate',
    'Breeder Note': 'BreederNote',
    'Contact Person': 'ContactPerson',
    Status: 'Status',
    Created: 'CreatedDateTime',
    'Created By': 'CreatedBy',
    Updated: 'LastUpdatedDateTime',
    'Updated By': 'LastUpdatedBy'
};

export const getUserNameColumns = () => {
    return ['CreatedBy', 'CreatedDatetime', 'ModifiedBy', 'ModifiedDateTime'];
};

export const numericColumns = [
    'SummaryDataId',
    'FileDetailId',
    'SelectionId',
    'Brix',
    'CullRate',
    'Firmness',
    'FlavorScore',
    'FruitSize',
    'MarketCondition',
    'Reversion',
    'OFTYear',
    // 'Stage',
    'MarketableYield',
    'PlantYield',
    'Diameter',
    'PlantAge'
    // 'Proprietary'
];

export const dateColumns = ['HarvestWeekBeg', 'HarvestWeekEnd', 'PlantingDate'];
