export const productionSystemValues = [
    'Organic long cane', 
    'Conventional long cane', 
    'Conventional Soil', 
    'Organic Soil', 
    'Organic Substrate', 
    'Conventional Substrate',
    'Tunneled conventional soil', 
    'Open Air conventional soil', 
    'Tunneled organic soil', 
    'Open air organic soil', 
    'Tunneled Substrate', 
    'Open Air Substrate', 
    'Tunneled organic substrate', 
    'Open Air organic substrate'
];
