import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import masterReducer from './MasterReducer';
import summaryReducer from './SummaryReducer';
import importFileReducer from './ImportFileReducer';
import templateReducer from '../apps/Template/reducers/TemplatesReducer';

const initialState = {
    showLoadingScreen: false,
    loadingScreenMessage: 'Loading...',
    pageTitle: '',
    commonDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'unknown content'
    },
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    toastMessage: '',
    toastType: ''
};

import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_COMMON_DIALOG,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    SHOW_TOAST,
    SERVICE_WORKER_UPDATED,
    SERVICE_WORKER_UPDATE
} from '../actions/actions';

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: (action.payload || '').length > 0 ? 'BSD: ' + action.payload : 'Breading Summary Data'
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_COMMON_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    isMessage: !!action.payload.isMessage
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                toastType: action.payload.toastType
            };
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    localize: localizeReducer,
    masterReducer,
    templateReducer,
    summaryReducer,
    importFileReducer
});
