import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { DrcButton, DrcIcons } from '@driscollsinc/driscolls-react-components';
import { DuIcons } from '@driscollsinc/driscolls-react-utilities';
import { berryMap } from '../data/constants';

const styles = (_theme) => ({
    headerButtons: {
        float: 'right',
        marginTop: '-42px',
        padding: '6px',
        minWidth: 0,
        margin: 4,
        marginRight: '60px',
        border: 'none !important',
        '& svg': {
            fontSize: 24
        },
        '& box-shadow': {
            boxShadow: 'none',
            backgroundColor: 'none'
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 500,
            width: '110px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    langPopover: {
        '& .MuiPaper-elevation8': {
            padding: '4px 12px',
            maxWidth: 400,
            width: '100%'
        }
    },
    language: {
        width: 120,
        float: 'right',
        height: 22
    },
    languageBtn: {
        width: '100%',
        margin: '8px 0'
    },
    langButtons: {
        width: '8rem !important',
        float: 'none !important',
        margin: '4px auto !important',
        border: 'none !important'
    },
    berryIcon: {
        padding: '0px 4px 0px 0px',
        margin: '2px auto 2px auto'
    },
    berryLabel: {
        padding: '0px 0px 4px 0px',
        fontSize: '1.2rem'
    }
});

class BerryToggle extends Component {
    state = {
        anchorEl: null
    };

    berries = Object.values(berryMap);

    handleBerryClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    setBerry = (berry) => () => {
        this.handleClose();
        this.props.setBerry(berry);
    };

    render() {
        const { classes, berry } = this.props;
        const { anchorEl } = this.state;
        return (
            <>
                <DrcButton
                    isSecondary
                    aria-describedby={'Language-popover'}
                    className={classes.headerButtons}
                    noStyle
                    onClick={this.handleBerryClick}
                >
                    <span className={classes.berryIcon}>{DrcIcons.GetBerryIcon(berry)}</span>
                    <span className={classes.berryLabel}>{DuIcons.GetBerryLabel(berry)}</span>
                </DrcButton>
                <Popover
                    id={'Language-popover'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    className={classes.popover}
                >
                    {this.berries.map((berryEle) => (
                        <DrcButton key={berryEle} isSecondary onClick={this.setBerry(berryEle)} className={classes.langButtons}>
                            <span className={classes.berryIcon}>{DrcIcons.GetBerryIcon(berryEle)}</span>
                            <span className={classes.berryLabel}>{DuIcons.GetBerryLabel(berryEle)}</span>
                        </DrcButton>
                    ))}
                </Popover>
            </>
        );
    }
}

export default withStyles(styles)(BerryToggle);
