const styles = (theme, height,maxHeight) => {
    return {
        '& .p-datatable-row.even': {
            backgroundColor: `${theme.light.grid.even} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.even} !important`
            }
        },
        '& .p-datatable-row.odd': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.odd} !important`
            }
        },
        '& .p-datatable-row:nth-of-type(2n+1)': {
            borderTop: '1px solid #dddddd',
            borderBottom: '1px solid #dddddd',
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-datatable-row:nth-of-type(2n)': {
            borderTop: '1px solid #dddddd',
            borderBottom: '1px solid #dddddd',
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-row-even': {
            backgroundColor: `${theme.light.grid.even} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.even} !important`
            }
        },
        '& .p-row-odd': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.odd} !important`
            }
        },
        '& .p-datatable-thead': {
            background: 'none',
            [theme.darkTheme]: {
                borderBottom: 'solid 1px #222',
                color: '#000'
            }
        },
        '& .p-row:nth-of-type(2n+1)': {
            borderTop: '1px solid #dddddd',
            borderBottom: '1px solid #dddddd',
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-row:nth-of-type(2n)': {
            borderTop: '1px solid #dddddd',
            borderBottom: '1px solid #dddddd',
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-datatable-scrollable-wrapper': {
            borderRadius: '4px',
            overflow: 'hidden',
            borderTop: 'solid 1px #dddddd',
            borderLeft: 'solid 1px #dddddd',
            borderRight: 'solid 1px #dddddd',
            borderBottom: 'solid 1px #dddddd',
            [theme.darkTheme]: {
                borderTop: 'solid 1px #222',
                borderLeft: 'solid 1px #222',
                borderRight: 'solid 1px #222',
                borderBottom: 'solid 1px #222'
            }
        },
        '& .p-datatable-wrapper': {
            maxHeight: maxHeight ? maxHeight : 'calc(100vh - 280px)',
            borderRadius: '4px',
            overflow: 'auto',
            borderTop: 'solid 1px #dddddd',
            borderLeft: 'solid 1px #dddddd',
            borderRight: 'solid 1px #dddddd',
            borderBottom: 'solid 1px #dddddd',
            [theme.darkTheme]: {
                borderTop: 'solid 1px #222',
                borderLeft: 'solid 1px #222',
                borderRight: 'solid 1px #222',
                borderBottom: 'solid 1px #222'
            }
        },
        '& .p-datatable-scrollable-body': {
            height: height ? height : 'calc(100vh - 280px)'
        },
        '& .p-inputtext.p-component.p-column-filter': {
            borderRadius: '4px'
        },
        '& .p-dropdown label.p-dropdown-label': {
            textOverflow: 'ellipsis',
            width: '88%'
        },
        '& .p-datatable-tbody tr td': {
            fontSize: '13px',
            [theme.darkTheme]: {
                color: '#aaa'
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        '& .p-datatable-tbody tr': {
            height: 48
        },
        '& .p-datatable-thead tr': {
            height: 40
        },
        '& .p-paginator': { display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: '0.4em' },
        '& .p-paginator-left-content': { marginRight: 'auto' },
        '& .p-paginator-right-content': { marginLeft: 'auto' },
        '& .p-paginator-page': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: 3,
            justifyContent: 'center',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            minWidth: '2.357rem',
            height: '2.357rem',
            fontSize: '1.2rem',
            [theme.darkTheme]: {
                color: '#aaa'
            }
        },
        '& .p-paginator-next': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            [theme.darkTheme]: {
                color: '#aaa'
            }
        },
        '& .p-paginator-last': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            [theme.darkTheme]: {
                color: '#aaa'
            }
        },
        '& .p-paginator-first': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            [theme.darkTheme]: {
                color: '#aaa'
            }
        },
        '& .p-paginator-prev': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            [theme.darkTheme]: {
                color: '#aaa'
            }
        },
        '& .p-paginator-current': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '2',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            fontSize: '14px',
            [theme.darkTheme]: {
                color: '#aaa'
            }
        },
        '& .p-paginator-element-focus': { zIndex: '1', position: 'relative' },
        '& .p-paginator .p-paginator-pages .p-paginator-page.p-highlight': {
            backgroundColor: '#4a773c',
            color: '#fff'
        },
        '& .pi': {
            fontSize: '18px'
        },
        '& .checkboxDisabled  .p-checkbox .p-checkbox-box': {
            border: `1px solid ${theme.palette.grey['A200']}`,
            opacity: '0.5',
            cursor: 'default'
        },
        '& .p-checkbox .p-checkbox-icon': {
            cursor: 'default',
            display: 'block !important'
        },
        '& .p-checkbox-box': {
            display: 'block !important'
        }
    };
};

const GridStyles = {
    styles: styles
};

export default GridStyles;
