import SummaryData from './summaryData';
import LogData from './logData';

function ConfigureAllFakeData() {
    SummaryData.ConfigureFakeData();
    LogData.ConfigureFakeData();
}

const Fake = {
    ConfigureAllFakeData
};

export default Fake;
