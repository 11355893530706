import SummaryData from './summaryData';

function ConfigureAllMiddleware() {
    SummaryData.ConfigureMiddleware();
}

const Middleware = {
    ConfigureAllMiddleware
};

export default Middleware;
