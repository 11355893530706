import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { productionSystemValues } from '../../constants';
//mport {decimal} from '../../utils/validations.js';

const checkLength = (length, field, row) => {
    if (typeof row[field] === 'string') {
        return row[field].length <= length ? false : `${field} is longer than ${length} characters`;
    } else if (typeof row[field] === 'number') {
        return row[field].toString().length <= length ? false : `${field} is longer than ${length} characters`;
    }
    return false;
};

const validateOptions = (field, row, options) => {
    if (!options.includes(row[field])) {
        return `${field} must have one of the following options ${options.join(',')}`;
    }
    return false;
};

const isInt = (value) => !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
const isDecimal = (field, row) => {
    if (!/^(?:\d*\.\d+|\d+)$/.test(row[field]) && !isEmpty(row[field])) {
        return `${field} should have at max 8 digits`;
    } else if (!/^(?:\d{0,8}\.\d+|\d{0,8})$/.test(row[field])) {
        return `${field} should have at max 8 digits`;
    }
    return false;
};

const checkYear = (field, row) => {
    //1900-2099
    if (!/^(19|20)\d{2}$/.test(row[field]) && !isEmpty(row[field])) {
        return `${field} should be year`;
    }
    return false;
};

const isEmpty = (data) => data === null || data === undefined || data === '';
const convertDate = (dateValue) => {
    let dateParts = dateValue.split('-');
    let d;
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    if (months.indexOf(dateParts[0].toLowerCase()) > -1) {
        //Sep-23-2021
        let month = months.indexOf(dateParts[0].toLowerCase());
        d = new Date(+dateParts[2], month + 1, dateParts[1] - 1);
    } else {
        d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }
    return d;
};
const validateDate = (row, field, excelCheck = true) => {
    //DD-MMM-YYYY
    if (excelCheck) {
        let excelToJsDate = DuDateUtilities.getDateFromExcelTimeStamp(row[field]);
        if (excelToJsDate === 'N/A') {
            let d = convertDate(row[field]);
            if (!(Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime()))) {
                return `${field} has to be in valid date format`;
            }
        }
    } else {
        let d = new Date(row[field]);
        if (!(Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime()))) {
            return `${field} has to be in valid date format`;
        }
    }
    if (row['Harvest Week Beg'] && row['Harvest Week End'] && field === 'Harvest Week Beg') {
        if (row['Harvest Week End'] <= row['Harvest Week Beg']) {
            return `Harvest Week End should be more than Harvest Week Beg`;
        }
    }
    return false;
};

const validate = (data, _berryType, _columns) => {
    const requiredFields = ['Selection', 'Field Code', 'Location', 'Cycle']; //'Variety Name'
    const boolColumns = ['Spines', 'Proprietary'];
    const yearColumn = ['Commercialized Projection', 'Cross Made', 'Plant Age'];
    const dateCols = ['Harvest Week Beg', 'Harvest Week End', 'Planting Date'];
    const errors = [];
    const integerFields = ['OFT Year']; //'Plant Age','Commercialized Projection',
    const decimalFields = [
        'Brix (Degree)',
        'Cull Rate (% bad)',
        'Firmness (g/mm)',
        'Flavor Score',
        'Fruit Size (g/berry)',
        'Marketable Yield (Kg/Ha)',
        'Market Condition',
        'Reversion',
        'Plant Yield (Kg/plant)',
        'Diameter (mm)'
    ]; //'Cross Made',
    const varCharFields = [
        'Business Solution',
        'Business Unit',
        'Challenges',
        'Description',
        'Stage',
        'Production System',
        'Contact Person',
        'Breeder Note',
        'Cycle'
    ];

    let hasErrors = false;

    try {
        data.forEach((row, _indx) => {
            let rowErrors = [];

            Object.keys(row).forEach((field) => {
                //validate required fields
                let error = null;

                if (requiredFields.includes(field) && isEmpty(row[field])) {
                    rowErrors.push(`${field} cannot be empty`);
                }

                if (decimalFields.includes(field)) {
                    error = isDecimal(field, row);
                }

                if (boolColumns.includes(field)) {
                    //check bool values
                    if (
                        !(
                            typeof row[field] === 'boolean' ||
                            isEmpty(row[field]) ||
                            (field === 'Spines' && ['yes', 'no'].includes((row[field] || '').toString().toLowerCase().trim())) ||
                            (field === 'Spines' && ['0', '1', 0, 1].includes(row[field])) ||
                            (field === 'Proprietary' && ['yes', 'no'].includes((row[field] || '').toString().toLowerCase().trim())) ||
                            (field === 'Proprietary' && ['0', '1', 0, 1].includes(row[field]))
                        )
                    ) {
                        rowErrors.push(`${field} is not valid please enter boolean value, `);
                    }
                }
                if (integerFields.includes(field)) {
                    //check integer values
                    if (!isInt(row[field]) && !isEmpty(row[field])) {
                        error = `${field} should be integer`;
                    } else if (row[field] > 2147483647) {
                        error = `${field} should not exceed 2147483647`;
                    }
                }
                if (varCharFields.includes(field)) {
                    //check length
                    if (
                        field === 'Business Solution' ||
                        field === 'Challenges' ||
                        field === 'Description' ||
                        field === 'Breeder Note' ||
                        field === 'Gains'
                    ) {
                        error = checkLength(1000, field, row);
                    } else if (field === 'Contact Person' || field === 'Cycle') {
                        error = checkLength(100, field, row);
                    } else if (field === 'Business Unit') {
                        let allowedValues = ['DOTA', 'DEMEA', 'DOC', 'DANZ'];
                        if (!(isEmpty(row[field]) || allowedValues.includes((row[field] || '').toString().toUpperCase()))) {
                            error = `${field} is not valid`;
                        }
                    } else if (field === 'Stage') {
                        error = checkLength(11, field, row);
                    } else if (field === 'Production System') {
                        error = validateOptions(field, row, productionSystemValues);
                    }
                }
                if (yearColumn.includes(field)) {
                    //check year values are matching lenth
                    error = checkLength(4, field, row);
                }
                if (dateCols.includes(field)) {
                    //check date values YYYY/MM/DD
                    error = validateDate(row, field, true);
                }

                if (error) {
                    rowErrors.push(error);
                }
            });
            if (rowErrors.length) hasErrors = true;

            errors.push(rowErrors);
        });
    } catch (err) {
        console.error(err);
    }
    return hasErrors ? errors : [];
};

export default validate;
