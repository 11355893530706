import { Middleware } from '@driscollsinc/one-ring';
const getHeaderFilesMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('A/B Flavor Score', 'abFlavorScore'),
    Middleware.CreateMapping('Selection ID', 'selectionId'),
    Middleware.CreateMapping('Selection', 'Selection'),
    Middleware.CreateMapping('Deployment Phase', 'deploymentPhase'),
    Middleware.CreateMapping('Advanced to OFT', 'advancedToOFT'),
    Middleware.CreateMapping('Appearance', 'Appearance'),
    Middleware.CreateMapping('Brix', 'brix'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('Business Solution', 'businessSolution'),
    Middleware.CreateMapping('Business Unit', 'businessUnit'),
    Middleware.CreateMapping('Challenges', 'challenges'),
    Middleware.CreateMapping('Commercialized Projection', 'comercializedProjection'),
    Middleware.CreateMapping('Cross Made', 'crossMade'),
    Middleware.CreateMapping('Cull Rate', 'cullRate'),
    Middleware.CreateMapping('Description', 'description'),
    Middleware.CreateMapping('Firmness', 'firmness'),
    Middleware.CreateMapping('Flavor Score', 'flavorScore'),
    Middleware.CreateMapping('Fruit Size', 'Fruit Size'),
    Middleware.CreateMapping('Gains', 'gains'),
    Middleware.CreateMapping('Harvest Weeks', 'harvestWeeks'),
    Middleware.CreateMapping('Market Condition', 'marketCondition'),
    Middleware.CreateMapping('Plot ID', 'plotId'),
    Middleware.CreateMapping('Field Codes', 'fieldCodes'),
    Middleware.CreateMapping('OFT Year', 'oftYear'),
    Middleware.CreateMapping('Pipeline', 'pipeline'),
    Middleware.CreateMapping('Pipeline Firmness', 'pipelineFirmness'),
    Middleware.CreateMapping('Pipeline Flavor', 'pipelineFlavor'),
    Middleware.CreateMapping('Pipeline Fruit Size', 'pipelineFruitSize'),
    Middleware.CreateMapping('Pipeline Harvest Efficiency', 'pipelineHarvestEffiency'),
    Middleware.CreateMapping('Pipeline Main Vulnerability', 'pipeLineMainVulnerability'),
    Middleware.CreateMapping('Pipeline Program', 'pipelineProgram'),
    Middleware.CreateMapping('Pipeline Shelf Life', 'pipelineShelfLife'),
    Middleware.CreateMapping('Pipeline Timing', 'pipelineTiming'),
    Middleware.CreateMapping('Pipeline Yield', 'pipelineYield'),
    Middleware.CreateMapping('Replacements', 'replacements'),
    Middleware.CreateMapping('Reversion', 'reversion'),
    Middleware.CreateMapping('Shelf Life', 'shelfLife'),
    Middleware.CreateMapping('Stage', 'stage'),
    Middleware.CreateMapping('Sub Program Pipeline', 'subProgramPipeline'),
    Middleware.CreateMapping('Test Plot ID', 'testPlotId'),
    // Middleware.CreateMapping('Test Plot Marketable Yield', 'Test Plot Marketable Yield'),
    // Middleware.CreateMapping('Test Plot Name', 'Test Plot Name'),
    Middleware.CreateMapping('Test Plot Yield', 'testPlotYield'),
    Middleware.CreateMapping('Yield weeks', 'yieldWeeks'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('UpdatedDateTime', 'UpdatedDateTime'),
    Middleware.CreateMapping('UpdatedBy', 'UpdatedBy')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SummaryData', 'GET')) {
        Middleware.RegisterMapping('SummaryData', 'GET', getHeaderFilesMapping);
    }
}

const SummaryData = {
    ConfigureMiddleware
};

export default SummaryData;
