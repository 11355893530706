import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcTranslate, DrcPanel, DrcChips } from '@driscollsinc/driscolls-react-components';

const styles = (_theme) => ({
    container: {
        maxWidth: '100% !important',
        width: '100%',
        border: 'none',
        padding: 0,
        marginBottom: 0
    }
});

class FilterSelect extends Component {
    makeChips = (selectedFilters) => {
        let chips = [];
        let key = 1;
        selectedFilters.map((filter) => {
            if (filter.hasOptions || filter.isAsync) {
                chips.push({
                    key,
                    fieldName: filter.fieldName,
                    value: filter.selected.value,
                    label: (
                        <>
                            <DrcTranslate>{filter.displayName || filter.fieldName}</DrcTranslate>: {filter.selected.label}
                        </>
                    )
                });
                key++;
            } else {
                chips.push({
                    key,
                    fieldName: filter.fieldName,
                    label: (
                        <>
                            <DrcTranslate>{filter.displayName || filter.fieldName}</DrcTranslate> : {filter.value}
                        </>
                    )
                });

                key++;
            }
            // lint fix
            return filter;
        });
        return chips;
    };

    handleDelete = (chip) => {
        this.props.filterDeselect(chip);
    };

    render() {
        const { classes, selectedFilters } = this.props;
        return (
            <>
                {selectedFilters.length ? (
                    <DrcPanel className={classes.container}>
                        <DrcChips chips={this.makeChips(selectedFilters)} onDelete={this.handleDelete} />
                    </DrcPanel>
                ) : (
                    ''
                )}
            </>
        );
    }
}

export default withStyles(styles)(FilterSelect);
