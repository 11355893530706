// This is been copied from BUS. Will add it to styleguide after a round of testing
// in MDM

import { withStyles, withTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';

const styles = (theme) => ({
    root: {
        height: 35,
        margin: '5px 0 12px',
        paddingTop: '2px',
        '&.multi': {
            height: 'auto',
            marginBottom: -2
        },
        '& .select > div:first-of-type': {
            minHeight: 35
        },
        '&.multi .select > div:first-of-type': {
            height: 'auto'
        },
        '& .select': {
            top: -15
        }
    },
    label: {
        backgroundColor: theme.light.primary,
        color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
        fontSize: '.75rem',
        padding: '2px 4px',
        marginLeft: '10px',
        position: 'relative',
        top: '-6px',
        zIndex: 2,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary,
            color: theme.dark.text.primary
        },
        '& span': {
            fontSize: '.75rem',
            color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : '')
        }
    },
    labelError: {
        color: theme.light.text.errorTitle + theme.important,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle + theme.important
        }
    },
    pError: {
        margin: '-5px 12px 0',
        fontSize: '0.75rem',
        lineHeight: '1em',
        letterSpacing: '0.03333em',
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

const customStyles = {
    menu: (provided, _state) => ({
        ...provided,
        position: 'absolute',
        zIndex: 999999
    }),
    control: (provided, _state) => ({
        ...provided,
        outline: 'none',
        height: '35px',
        width: '100%',
        backgroundColor: 'transparent',
        marginTop: '-12px'
    }),
    menuPortal: (provided, _state) => ({
        ...provided,
        zIndex: '1400'
    })
};

let colorSchemeWatch = window.matchMedia('(prefers-color-scheme: dark)');

// has to go into style guide
class TypeAheadAsync extends Component {
    state = {
        styleKey: '',
        styles: {}
    };

    matchMediaWatch = (e) => {
        let isDarkTheme = e.matches;
        let isError = (this.props.helperText || '').length > 0;
        let styleKey = isDarkTheme.toString() + isError.toString();

        if (this.state.styleKey !== styleKey) {
            this.onLoad();
        }
    };

    onLoad = () => {
        let isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
        let isError = (this.props.helperText || '').length > 0;
        let styleKey = isDarkTheme.toString() + isError.toString();

        if (this.state.styleKey !== styleKey) {
            const { theme } = this.props;

            const stylesLoad = {
                primary25: isDarkTheme ? theme.dark.accent.brightness(-10) : theme.light.accent.brightness(10),
                primary: isDarkTheme ? theme.dark.accent.primary : theme.light.accent.primary,
                neutral0: isDarkTheme ? theme.dark.primary : theme.light.primary, //option background
                neutral5: isDarkTheme ? theme.dark.variant.primary : theme.light.variant.primary, //Unknown
                neutral10: isDarkTheme ? theme.dark.secondary : theme.light.secondary, //multi-item-box
                neutral20: isError
                    ? isDarkTheme
                        ? theme.dark.error
                        : theme.light.error
                    : isDarkTheme
                    ? theme.dark.buttonBorder
                    : theme.light.buttonBorder, //Border
                neutral30: isError
                    ? isDarkTheme
                        ? theme.dark.error
                        : theme.light.error
                    : isDarkTheme
                    ? theme.dark.text.primary
                    : theme.light.text.primary, //Border Hover
                neutral80: isDarkTheme ? theme.dark.text.primary : theme.light.text.primary, //Text
                dangerLight: isDarkTheme ? theme.dark.buttonBorder : theme.light.buttonBorder, //multi-clear-btn
                danger: isDarkTheme ? theme.dark.text.primary : theme.light.text.primary //multi-clear-x
            };

            this.setState({ styleKey, stylesLoad });
        }
    };

    componentDidMount() {
        colorSchemeWatch.addListener(this.matchMediaWatch);
        this.onLoad();
    }

    componentDidUpdate() {
        this.onLoad();
    }

    componentWillUnmount() {
        colorSchemeWatch.removeListener(this.matchMediaWatch);
    }
    render() {
        const {
            className,
            isClearable,
            onChange,
            loadOptions,
            onInputChange,
            label,
            required,
            classes,
            hideLabel,
            helperText,
            isMulti,
            placeholder,
            selectedOption = []
        } = this.props;
        let isError = (helperText || '').length > 0;
        let title = label || this.props.name;
        return (
            <div className={`${classes.root} ${isMulti ? 'multi' : ''} ${className || ''}`}>
                {!hideLabel ? (
                    <label className={`${classes.label} ${isError ? classes.labelError : ''}`} id={title + 'Lbl'}>
                        {required ? (
                            <span>
                                {title} <span className={classes.labelError}>*</span>
                            </span>
                        ) : (
                            title
                        )}
                    </label>
                ) : null}
                <AsyncSelect
                    menuPortalTarget={document.body}
                    style={{ marginTop: '-10px' }}
                    isClearable={isClearable}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    cacheOptions
                    defaultOptions
                    placeholder={placeholder || 'Input Value'}
                    loadOptions={loadOptions}
                    styles={customStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            ...this.state.styles
                        }
                    })}
                    // {...(selectedOption.length ? { value: selectedOption } : undefined)}
                    value={selectedOption}
                />
            </div>
        );
    }
}

export default withTheme(withStyles(styles)(TypeAheadAsync));
