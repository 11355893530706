class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static BUS_BASE = window.config.BUS_BASE_ADDRESS;
    static GET_SUMMARY_DATA = (startPage = 0, pageSize = 0, berryType, selection, status, cycle, location, fieldCode) => {
        let params = '';
        if (berryType) {
            params = `${params}&berrytype=${berryType}`;
        }
        if (selection) {
            params = `${params}&selection=${selection}`;
        }
        if (status) {
            params = `${params}&status=${status}`;
        }
        if (cycle) {
            params = `${params}&cycle=${cycle}`;
        }
        if (location) {
            params = `${params}&location=${location}`;
        }
        if (fieldCode) {
            params = `${params}&fieldcode=${fieldCode}`;
        }
        return `${this.BASE}summarydata?startpage=${startPage}&pagesize=${pageSize}${params}`;
    };
    static FETCH_SUMMARY_DATA = (berryType, selection, status, cycle, location, fieldCode) => {
        let params = '';
        if (berryType) {
            params = `${params}berrytype=${berryType}`;
        }
        if (selection) {
            params = `${params}&selection=${selection}`;
        }
        if (status) {
            params = `${params}&status=${status}`;
        }
        if (cycle) {
            params = `${params}&cycle=${cycle}`;
        }
        if (location) {
            params = `${params}&location=${location}`;
        }
        if (fieldCode) {
            params = `${params}&fieldcode=${fieldCode}`;
        }
        return `${this.BASE}summarydata?${params}`;
    };
    static editSummaryData = this.BASE + 'summarydata';

    static SELECTION_SEARCH = (berryType, selection) => {
        let params = '';
        if (berryType) {
            params = `${params}&berrytype=${berryType}`;
        }
        if (selection) {
            params = `${params}&selection=${selection}`;
        }
        return `${this.BASE}summarydata/selection?${params}`;
    };

    static GET_S3_CREDENTIALS = (name) => `${this.BUS_BASE}File/Upload/?name=${name}&filetype=text/csv&folderName=SummaryData`;

    static bulkUpdate = this.BASE + 'summarydata/bulkupdate';

    static GET_GRAPH_DATA = (summaryDataId, graphName) => `${this.BASE}graphdata?summarydataid=${summaryDataId}&graphname=${graphName}`;

    static POST_GRAPH_DATA = `${this.BASE}graphdata`;

    static GET_POOLWEEK = (startdate, enddate) => `${this.BASE}poolweek?startdate=${startdate}&enddate=${enddate}`;
}

export default APIEndPoints;
