import React, { useEffect, useState } from 'react';
import { DrcDatePicker, DrcDialog, DrcPanel, DrcButton, DrcSwitch, DrcTranslate, DrcSelect } from '@driscollsinc/driscolls-react-components';
import BCMFormInput from '../../../components/BCMFormInput';
import BCMFormButton from '../../../components/BCMFormButton';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { format, isValid } from 'date-fns';
import { required, alphaNumeric, maxlen, decimalWithFixedLength, precision, maxValue, positiveInteger } from '../../../utils/validations';
import Form from 'react-validation/build/form';

const styles = (_theme) => ({
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1200px !important', //TO override dialog maxWidth
            maxHeight: '83vh',
            minHeight: '83vh'
        }
    },
    asyncItem: {
        height: '45px',
        width: '100%',
        alignSelf: 'center',
        marginTop: '1px'
    }
});

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const EditDialog = ({ classes, open, closeDialog, rowData, metadata, save, id, title = '' }) => {
    const [tabValue, setTabValue] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [values, setValues] = useState({});
    const [invalidTabs, setInvalidTabs] = useState([]);
    const [readOnlyFields, setReadOnlyFieldsFields] = useState({});

    useEffect(() => {
        setTabValue(0);
        rowData != null && buildValues();
    }, [open, rowData]);

    useEffect(() => {
        buildTabs();
    }, [values, tabValue, invalidTabs]);

    const handleTabChange = (_event, newValue) => {
        setTabValue(newValue);
    };

    const handleChange = (key, value) => {
        let vals = { ...values };
        vals[key] = value;
        setValues(vals);
    };

    const buildValues = () => {
        const vals = {};
        metadata.map((d) => {
            for (let key in d.fields) {
                d.fields[key].value = rowData[key] ? rowData[key] : '';
                vals[key] = d.fields[key].value;
            }
        });
        setValues(vals);
    };

    //This put an * in the tabs  when there is a field that triggered  some validation
    const handleValidationDetected = (key, validate) => {
        if (validate === undefined) {
            return;
        }
        const data = metadata.find((m) => {
            return m.fields[key] != null;
        });
        if (validate) {
            setInvalidTabs([...invalidTabs, [data.tabName, key]]);
            return;
        }

        let invTabs = [...invalidTabs];
        invTabs = invTabs.filter((t) => {
            return t[0] !== data.tabName || t[1] !== key;
        });
        setInvalidTabs(invTabs);
    };

    const inputControl = (key, label, inputValue, dataType, isRequired, max, validations, readOnly, precisionValue, maxVal) => {
        return (
            <BCMFormInput
                label={<DrcTranslate>{label}</DrcTranslate>}
                name={key}
                value={inputValue}
                onChange={(e) => handleChange(key, e.target.value)}
                placeholder="Enter Value..."
                className="col-sm-12"
                style={{ margin: '5px 0 15px', padding: '0 5px' }}
                InputLabelProps={{ shrink: true, style: { paddingLeft: 5 } }}
                type={dataType}
                required={isRequired}
                validations={validations}
                maxlen={max ? max : 100}
                precision={precisionValue}
                maxvalue={maxVal}
                validationDetected={(validate) => handleValidationDetected(key, validate)}
                InputProps={{ readOnly: readOnly }}
            />
        );
    };
    const getDateProps = (key) => {
        if (key === 'harvestWeekBeg') {
            if (values['harvestWeekEnd']) {
                let prevDay = new Date(values['harvestWeekEnd']);
                prevDay.setTime(prevDay.getTime() - 1000 * 3600 * 24);
                return {
                    maxDate: new Date(prevDay)
                };
            }
        } else if (key === 'harvestWeekEnd') {
            if (values['harvestWeekBeg']) {
                let nextDay = new Date(values['harvestWeekBeg']);
                nextDay.setTime(nextDay.getTime() + 1000 * 3600 * 24);
                return {
                    minDate: new Date(nextDay)
                };
            }
        }
        return {};
    };

    const buildTabs = () => {
        let index = 0;
        let tabsTags = [];
        let maxNumberFields = 0;
        const orderedDataByTabs = metadata.sort((a, b) => a.tabIndex - b.tabIndex);
        const tabPanels = orderedDataByTabs.map((d) => {
            let inputs = [];
            let numberOfFields = 0;
            for (let key in d.fields) {
                numberOfFields++;
                const dataType = d.fields[key].dataType;
                let inputValue = values[key];
                const isRequired = d.fields[key].required;
                let label = d.fields[key].displayName;
                if (d.fields[key].measurement) {
                    label = label + ' (' + d.fields[key].measurement + ')';
                }
                const precisionValue = d.fields[key].precision;
                const maxVal = d.fields[key].maxValue;
                let readOnly = false;
                if (readOnlyFields[key] !== undefined) {
                    readOnly = readOnlyFields[key];
                } else {
                    let roFields = {};
                    readOnly = d.fields[key].readOnly ? d.fields[key].readOnly : false;
                    roFields[key] = readOnly;
                    setReadOnlyFieldsFields({ ...roFields, ...readOnlyFields });
                }
                const inputValidations = [maxlen];

                dataType === 'decimal'
                    ? inputValidations.push(...[decimalWithFixedLength, precision])
                    : dataType === 'number'
                    ? inputValidations.push(...[positiveInteger, maxValue])
                    : inputValidations.push(alphaNumeric);
                if (isRequired) {
                    inputValidations.push(required);
                }
                switch (dataType) {
                    case 'text':
                    case 'number':
                    case 'decimal':
                        inputs.push(
                            <Grid item xs={6} sm={6} md={6} lg={6} key={key}>
                                {inputControl(
                                    key,
                                    label,
                                    inputValue,
                                    dataType,
                                    isRequired,
                                    d.fields[key].max,
                                    inputValidations,
                                    readOnly,
                                    precisionValue,
                                    maxVal
                                )}
                            </Grid>
                        );
                        break;
                    case 'bool':
                        if (typeof inputValue === 'string' || inputValue instanceof String) {
                            if (inputValue && inputValue.toUpperCase() === 'YES') {
                                inputValue = true;
                            } else {
                                inputValue = false;
                            }
                        }
                        if (typeof inputValue === 'boolean' || typeof inputValue === 'number') {
                            inputValue = inputValue ? true : false;
                        }
                        inputs.push(
                            <Grid item xs={6} sm={6} md={6} lg={6} key={key}>
                                <DrcSwitch
                                    onChange={(_event, checked) => handleChange(key, checked)}
                                    checked={!!inputValue}
                                    value={!!inputValue}
                                    style={{ margin: 'auto', padding: 'auto' }}
                                >
                                    {<DrcTranslate>{label}</DrcTranslate>}
                                </DrcSwitch>
                            </Grid>
                        );
                        break;
                    case 'dateTime':
                        inputs.push(
                            <Grid item xs={6} sm={6} md={6} lg={6} key={key}>
                                <DrcDatePicker
                                    label={<DrcTranslate>{label}</DrcTranslate>}
                                    required={isRequired}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    onChange={(date) => {
                                        handleChange(key, isValid(date) ? format(date, 'yyyy/MM/dd') : date);
                                    }}
                                    selectedDate={inputValue ? new Date(inputValue) : null}
                                    disabled={readOnly}
                                    emptyLabel=" "
                                    {...getDateProps(key)}
                                />
                            </Grid>
                        );
                        break;
                    case 'select':
                        inputs.push(
                            <Grid item xs={6} sm={6} md={6} lg={6} key={key}>
                                <DrcSelect
                                    options={d.fields[key].options}
                                    name={key}
                                    required={isRequired}
                                    label={<DrcTranslate>{label}</DrcTranslate>}
                                    onChange={(option) => handleChange(key, option.value)}
                                    value={d.fields[key].options.find(
                                        (itm) => (itm.value || '').trim().toUpperCase() === (inputValue || '').trim().toUpperCase()
                                    )}
                                />
                            </Grid>
                        );
                        break;
                }
            }
            maxNumberFields = Math.max(maxNumberFields, numberOfFields);

            const invalidTab = invalidTabs.some((t) => t[0] === d.tabName);

            tabsTags.push(<Tab label={invalidTab ? d.tabName + '*' : d.tabName} {...a11yProps(index)} />);

            const tabPanel = (
                <TabPanel value={tabValue} index={index} key={d.tabName}>
                    <DrcPanel>
                        <Grid container spacing={2}>
                            {inputs}
                        </Grid>
                    </DrcPanel>
                </TabPanel>
            );
            index++;
            return tabPanel;
        });
        const tabsAndPanels = [tabsTags, tabPanels];
        setTabs(tabsAndPanels);
    };

    const handleOnSave = () => {
        const editedRow = values;
        //select inputs return and object instead an values (string int etc), therefore we check if some value is an object and if it is we take the value from that object
        for (let key in editedRow) {
            if (typeof editedRow[key] === 'object') {
                editedRow[key] = editedRow[key].value;
            }
        }
        editedRow[id] = rowData[id];
        save(editedRow);
        closeDialog();
    };

    return (
        <Form>
            <DrcDialog
                title={title}
                className={classes.dialogWrapper}
                open={open}
                buttons={
                    <>
                        <DrcButton
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            <DrcTranslate>{'Close'}</DrcTranslate>
                        </DrcButton>
                        <BCMFormButton isPrimary onClick={handleOnSave} disabled={!!invalidTabs.length}>
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </BCMFormButton>
                    </>
                }
            >
                <Tabs value={tabValue} onChange={handleTabChange}>
                    {tabs[0]}
                </Tabs>
                {tabs[1]}
            </DrcDialog>
        </Form>
    );
};

const TabPanel = ({ children, index, value, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {children}
        </div>
    );
};

export default withStyles(styles)(EditDialog);
