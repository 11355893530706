import axios from 'axios';

export const uploadToS3 = async (presignedUrl, file) => {
    let data = file;

    let config = {
        method: 'put',
        url: presignedUrl,
        headers: {
            'Content-Type': 'text/csv'
        },
        data: data
    };
    try {
        let response = await axios(config);
        return response.data;
    } catch (error) {
        return error;
    }
};
