const data = {
    totalCount: 9,
    Results: [
        {
            Id: 1,
            selection: 'BA177.1',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: false,
            cullRate: 52,
            fruitSize: 8.7,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 9,
            firmness: 2.5,
            flavorScore: 4,
            marketCondition: 5,
            reversion: 2.7,
            businessSolution: '',
            businessUnit: 'DANZ',
            challenges: '',
            commercializedProjection: 1995,
            crossMade: 1995,
            description: '',
            gains: '',
            oftYear: '',
            stage: 95,
            proprietary: false,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Organic substract',
            status: 'Approved',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 2,
            selection: 'BD467.1',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: false,
            cullRate: 48,
            fruitSize: 7.7,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 11,
            firmness: 3.3,
            flavorScore: 6.5,
            marketCondition: 3,
            reversion: 3,
            businessSolution: '',
            businessUnit: 'DOC',
            challenges: '',
            commercializedProjection: 1998,
            crossMade: 1998,
            description: '',
            gains: '',
            oftYear: '',
            stage: 95,
            proprietary: false,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Organic substract',
            status: 'Approved',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 3,
            selection: 'BJ110.2',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: true,
            cullRate: 16,
            fruitSize: 9.3,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 10,
            firmness: 1,
            flavorScore: 6,
            marketCondition: 3,
            reversion: 1,
            businessSolution: '',
            businessUnit: 'DOC',
            challenges: '',
            commercializedProjection: 2000,
            crossMade: 2000,
            description: '',
            gains: '',
            oftYear: '',
            stage: 9,
            proprietary: false,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Organic substract',
            status: 'Approved',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 4,
            selection: 'BL405.2',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: true,
            cullRate: 36,
            fruitSize: 10.2,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 13,
            firmness: 1.3,
            flavorScore: 7,
            marketCondition: 6,
            reversion: 3,
            businessSolution: '',
            businessUnit: 'DEMEA',
            challenges: '',
            commercializedProjection: 2001,
            crossMade: 2001,
            description: '',
            gains: '',
            oftYear: '',
            stage: 94,
            proprietary: true,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Conveniont soil',
            status: 'Draft',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 5,
            selection: 'BL456.2',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: false,
            cullRate: 24,
            fruitSize: 7.6,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 10,
            firmness: 2,
            flavorScore: 5.8,
            marketCondition: 7,
            reversion: 4,
            businessSolution: '',
            businessUnit: 'DOTA',
            challenges: '',
            commercializedProjection: 2001,
            crossMade: 2001,
            description: '',
            gains: '',
            oftYear: '',
            stage: 9,
            proprietary: true,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Organic substract',
            status: 'Discarded',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 6,
            selection: 'BQ723.4',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: true,
            cullRate: 17,
            fruitSize: 9.2,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 12,
            firmness: 2.8,
            flavorScore: 6.8,
            marketCondition: 7,
            reversion: 2.6,
            businessSolution: '',
            businessUnit: 'DANZ',
            challenges: '',
            commercializedProjection: 2006,
            crossMade: 2006,
            description: '',
            gains: '',
            oftYear: '',
            stage: 94,
            proprietary: true,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Organic soil',
            status: 'Draft',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 7,
            selection: 'BR401.2',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: false,
            cullRate: 35,
            fruitSize: 10.9,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 10,
            firmness: 1.9,
            flavorScore: 6.2,
            marketCondition: 5,
            reversion: 2.2,
            businessSolution: '',
            businessUnit: 'DOC',
            challenges: '',
            commercializedProjection: 2009,
            crossMade: 2009,
            description: '',
            gains: '',
            oftYear: '',
            stage: 9,
            proprietary: true,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Conveniont soil',
            status: 'Draft',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 8,
            selection: 'BS603.23',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: false,
            cullRate: 26,
            fruitSize: 10,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 13,
            firmness: 1.5,
            flavorScore: 7,
            marketCondition: 5,
            reversion: 3,
            businessSolution: '',
            businessUnit: 'DEMEA',
            challenges: '',
            commercializedProjection: 2010,
            crossMade: 2010,
            description: '',
            gains: '',
            oftYear: '',
            stage: 94,
            proprietary: true,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Conveniont soil',
            status: 'Draft',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        },
        {
            Id: 9,
            selection: 'BS648.64',
            fieldCode: 'WBT0320',
            location: '',
            varietyName: '',
            spines: true,
            cullRate: 26,
            fruitSize: 8.8,
            marketableYield: '',
            plantYield: '',
            diameter: '',
            brix: 14,
            firmness: 1.6,
            flavorScore: 7.5,
            marketCondition: 3,
            reversion: 2,
            businessSolution: '',
            businessUnit: 'DOC',
            challenges: '',
            commercializedProjection: 2010,
            crossMade: 2010,
            description: '',
            gains: '',
            oftYear: '',
            stage: 94,
            proprietary: true,
            dateOfMostRecentDataUpdate: '',
            breederNote: '',
            contactPerson: '',
            plantAge: '',
            harvestWeekBeg: '',
            harvestWeekEnd: '',
            cycle: '',
            plantingDate: '',
            productionSystem: 'Organic soil',
            status: 'Approved',
            CreatedDateTime: '2021-07-16T06:24:25.042Z',
            CreatedBy: 'kapil.pandey',
            UpdatedDateTime: '2021-07-16T06:24:25.042Z',
            UpdatedBy: 'kapil.pandey'
        }
    ]
};

export default data;
