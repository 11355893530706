import { SET_MASTER_DATA_REDIRECT_URL, SET_MASTER_DATA_INITIALIZED, SET_BERRY } from '../actions/MasterActions';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/Home/';
const REDIRECT_URL = sessionStorage.getItem(REDIRECT_URL_KEY) || DEFAULT_REDIRECT_URL;

const initialState = {
    isInitialized: false,
    redirectUrl: REDIRECT_URL,
    berry: localStorage.getItem('selectedBerry') || 'STRAW'
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/' || action.payload === '/implicit/callback' || action.payload === '/InitializeApplication/') {
                return state;
            }

            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);

            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        case SET_BERRY:
            return Object.assign({}, state, {
                berry: action.payload
            });
        default:
            return state;
    }
};

export default masterReducer;
