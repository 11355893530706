import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { DrcInput, DrcTranslate, DrcSelect } from '@driscollsinc/driscolls-react-components';
import React, { Component } from 'react';
import TypeAheadAsync from '../../components/TypeAheadAsync';
import APIEndPoints from '../../apps/Data/services/api';
import { Middleware } from '@driscollsinc/one-ring';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { middlewareNoLoaderConfig } from '../../data/constants';

const styles = (_theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '1rem',
        width: '30%',
        minWidth: '20rem'
    },
    input: {
        marginTop: '11px'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row'
    },
    asyncItem: {
        height: '45px',
        width: '100%',
        alignSelf: 'center'
    },
    item: {
        width: '100%',
        alignSelf: 'center'
    }
});

class FilterItem extends Component {
    state = {
        typeAheadString: {}
    };

    makeOptions = (options, label, value) => {
        // here label and value are the key names that needto be used as label and value
        return options.map((e) => {
            e.label = e[label];
            e.value = e[value];
            return e;
        });
    };

    handleInput = (name) => (event) => {
        this.props.filterSelect(name, event.target.value);
        if (!event.target.value) {
            this.props.filterDeselect({ fieldName: name });
        }
    };

    loadOptions = (name) => async () => {
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            let searchStr = this.state.typeAheadString[name] || '';
            if (searchStr.trim() === '') return;
            let entities = await Middleware.Send(
                'DataEntityList',
                token,
                APIEndPoints.SELECTION_SEARCH(this.props.berry, searchStr),
                'GET',
                null,
                middlewareNoLoaderConfig
            );

            entities = entities
                .map((e) => {
                    if (searchStr) {
                        return { label: e, value: e };
                    }

                    return null;
                })
                .filter((e) => e);

            return entities;
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    onAsyncChange = (name) => (e) => {
        this.setState({
            typeAheadString: {
                ...this.state.typeAheadString,
                [name]: ''
            }
        });
        this.props.filterSelect(name, e);
    };

    onAsyncInputChange = (name) => (e) => {
        this.setState({
            typeAheadString: {
                ...this.state.typeAheadString,
                [name]: e
            }
        });
    };

    handleSelect = (name) => (event) => {
        this.props.filterSelect(name, event);
    };

    render() {
        const { classes, filter } = this.props;
        return (
            <div className={classes.flex}>
                <div className={classes.container}>
                    {!filter.hasOptions && !filter.isAsync && !filter.isDate && !filter.isBool && (
                        <DrcInput
                            label={filter.displayName || filter.fieldName}
                            className={classes.input}
                            onChange={this.handleInput(filter.fieldName)}
                            value={filter.value}
                        />
                    )}
                    {!filter.hasOptions && filter.isAsync && (
                        <TypeAheadAsync
                            className={classes.asyncItem}
                            label={<DrcTranslate>{filter.displayName || filter.fieldName}</DrcTranslate>}
                            loadOptions={this.loadOptions(filter.fieldName)}
                            onChange={this.onAsyncChange(filter.fieldName)}
                            onInputChange={this.onAsyncInputChange(filter.fieldName)}
                            selectedOption={filter.selected}
                        />
                    )}
                    {filter.hasOptions && !filter.isBerry && (
                        <DrcSelect
                            closeMenuOnSelect={false}
                            label={<DrcTranslate>{filter.displayName || filter.fieldName}</DrcTranslate>}
                            onChange={this.handleSelect(filter.fieldName)}
                            className={classes.item}
                            options={this.makeOptions(filter.options, filter.optionLabel, filter.optionValue)}
                            value={filter.selected}
                        ></DrcSelect>
                    )}
                </div>

                {/* <div className={classes.container}>
                {!filter.hasOptions && !filter.isAsync && !filter.isDate && !filter.isBool && (
                    <DrcInput
                        label="season data"
                        className={classes.input}
                        onChange={(event) => console.log(event)}
                    />
                )}
            </div> */}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        berry: state.masterReducer.berry
    };
}

export default withOktaAuth(connect(mapStateToProps)(withRouter(withStyles(styles)(FilterItem))));
