export const SET_FILTER = 'SET_FILTER';
export const setFilter = (filters) => ({
    type: SET_FILTER,
    payload: filters
});

export const CLEAR_SUMMARY_DATA = 'CLEAR_SUMMARY_DATA';
export const clearSummaryData = () => ({
    type: CLEAR_SUMMARY_DATA,
    payload: null
});

export const SET_SUMMARY_DATA = 'SET_SUMMARY_DATA';
export const setSummaryData = (records, count) => ({
    type: SET_SUMMARY_DATA,
    payload: { records, count }
});

export const SET_FILTERED_SUMMARY_DATA = 'SET_FILTERED_SUMMARY_DATA';
export const setFilteredSummaryData = (records, count) => ({
    type: SET_FILTERED_SUMMARY_DATA,
    payload: { records, count }
});

export const SET_UI_TO_API_KEY_MAP = 'SET_UI_TO_API_KEY_MAP';
export const setUiToApiMap = (map) => ({
    type: SET_UI_TO_API_KEY_MAP,
    payload: map
});
