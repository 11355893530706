import { withStyles } from '@material-ui/core/styles';
import { DuValidationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DrcTooltip } from '@driscollsinc/driscolls-react-components';
import React, { Component } from 'react';

const styles = (_theme) => ({
    dataTableCell: {
        fontSize: 13
    },
    emailAddress: {
        fontWeight: 600,
        fontSize: 13,
        color: '#4a773c',
        textDecoration: 'underline'
    }
});

class LabelFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { row, field, classes } = this.props;
        const isEmail = DuValidationUtilities.EMAIL_REGEXP.test(row[field]);
        const value = row[field];

        let stringValue = String(value);
        let maxlength = stringValue.length > 20 ? 20 : stringValue.length;
        let newValue = stringValue.substring(0, maxlength) ?? '';

        return isEmail ? (
            <span>
                <a className={classes.emailAddress} href={'mailto:' + value} title={value}>
                    {value}
                </a>
            </span>
        ) : // when the value is not 'null' or not greater than 15 show oiginal value.
        newValue !== 'null' && maxlength >= 15 ? (
            <DrcTooltip tipText={value}>
                <span className={classes.dataTableCell}>
                    {newValue}
                    {maxlength <= 20 ? '' : '…'}
                </span>
            </DrcTooltip>
        ) : (
            <span className={classes.dataTableCell}>{value}</span>
        );
    }
}

export default withStyles(styles)(LabelFormatter);
