import React, { useEffect } from 'react';
import { control } from 'react-validation';
import { DrcInput } from '@driscollsinc/driscolls-react-components';

const BCMFormInput = ({ error, isChanged, isUsed, validationDetected, ...props }) => {
    useEffect(() => {
        validationDetected((isUsed || isChanged) && !!error);
    }, [error, isChanged, isUsed]);
    return <DrcInput error={(isUsed || isChanged) && !!error} helperText={isUsed || isChanged ? error : ''} {...props} />;
};

export default control(BCMFormInput);
