import React, { Component } from 'react';
import FilterItem from './FilterItem';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import { connect } from 'react-redux';
import CreateFilter from '../../utils/filter';
import { setFilter } from '../../actions/SummaryAction';
import { filterFields } from '../../data/constants';
import FilterSelect from './FilterSelect';
const styles = (_theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flex: 1,
        border: `0.2px solid #b9b9b9`,
        padding: '8px',
        borderRadius: '4px'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    filterOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    filterButtonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    selectedFilter: {
        height: '2rem'
    },
    selectedContainer: {
        flex: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    }
});

class Filter extends Component {
    componentDidMount() {
        let filters = this.makefilter(filterFields);
        this.props.setFilter(filters);
    }

    makefilter = (filters) => filters.filter((e) => e.IsSearchable).map(CreateFilter);

    filterSelect = (fieldName, value) => {
        let updatedFilters = this.props.filters.map((filter) => {
            if (filter.fieldName === fieldName) {
                filter.isSelected = true;

                if (filter.hasOptions || filter.isAsync) {
                    if (value) {
                        filter.selected = value ? value : [];
                    } else {
                        filter.selected = filter.isBerry ? [value] : [...filter.selected, value];
                    }
                } else {
                    if (filter.isBool) {
                        filter.isSelected = value;
                    } else {
                        filter.value = value;
                    }
                }
                if (filter.hasOptions && !filter.selected) {
                    filter.isSelected = false;
                }
            }
            return { ...filter };
        });

        this.props.setFilter(updatedFilters);
    };

    resetFilter = () => {
        let clearedFilters = this.props.filters.map((filter) => {
            if (filter.isSelected) {
                if (filter.hasOptions || filter.isAsync) {
                    filter.isSelected = false;
                    filter.value = '';
                    filter.selected = [];
                } else {
                    filter.isSelected = false;
                    filter.value = '';
                }
            }
            return filter;
        });

        this.props.setFilter(clearedFilters);
        this.props.resetFilter();
    };

    filterDeselect = (filterToRemove) => {
        let atIndex = null;

        let filterToUpdate = this.props.filters.find((e, indx) => {
            if (e.fieldName === filterToRemove.fieldName) {
                atIndex = indx;
                return true;
            }
            return false;
        });
        let updatedFilters = [...this.props.filters];

        if (updatedFilters[atIndex].hasOptions) {
            filterToUpdate.selected = null;
        }
        if (updatedFilters[atIndex].isAsync) {
            filterToUpdate.selected = null;
        }

        if (!filterToUpdate.selected || (!updatedFilters[atIndex].hasOptions && !updatedFilters[atIndex].isAsync)) {
            filterToUpdate.isSelected = false;
            filterToUpdate.value = '';
        }

        updatedFilters[atIndex] = { ...filterToUpdate };
        this.props.setFilter(updatedFilters);
        let hasFilters = updatedFilters.filter((e) => e.isSelected).length;
        if (!hasFilters) {
            this.resetFilter();
        }
    };

    isSelected = () => this.props.filters.filter((e) => e.isSelected).length;

    applyFilter = () => {
        //clearDAta
        this.props.applyFilter();
    };

    render() {
        const { classes, filters, showFilter } = this.props;
        return (
            <>
                {showFilter ? (
                    <div className={classes.container}>
                        <div className={classes.filterContainer}>
                            {filters
                                .map((filter, indx) =>
                                    filter.fieldName !== 'BerryType' ? (
                                        <FilterItem
                                            key={indx}
                                            filter={filter}
                                            filterSelect={this.filterSelect}
                                            filterDeselect={this.filterDeselect}
                                        />
                                    ) : null
                                )
                                .filter((e) => e)}
                        </div>
                        <div className={classes.filterOptions}>
                            <div className={classes.selectedContainer}>
                                <FilterSelect selectedFilters={filters.filter((e) => e.isSelected)} filterDeselect={this.filterDeselect} />
                            </div>
                            <div className={classes.filterButtonContainer}>
                                {this.isSelected() ? (
                                    <DrcButton isPrimary className={classes.selectedFilter} onClick={this.applyFilter}>
                                        Apply
                                    </DrcButton>
                                ) : (
                                    ''
                                )}
                                {this.isSelected() ? (
                                    <DrcButton isSecondary className={classes.selectedFilter} onClick={this.resetFilter}>
                                        Reset
                                    </DrcButton>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setFilter: (filters) => dispatch(setFilter(filters))
});

function mapStateToProps({ summaryReducer }) {
    return {
        filters: summaryReducer.filters
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filter));
