import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcEnvironmentMarker,
    Helmet,
    DrcSecureHeader,
    DrcMediaQueries,
    DrcMain,
    DrcImage,
    DrcPageLogin,
    DrcPageNotFound,
    DrcImplicitCallback,
    DrcSecureGroupRoute,
    DrcLoading,
    DrcVersionInfo,
    DrcBackdrop,
    DrcDialog,
    DrcButton,
    DrcTranslate
} from '@driscollsinc/driscolls-react-components';
import { Middleware } from '@driscollsinc/one-ring';
import {
    showLoadingScreenAction,
    hideLoadingScreenAction,
    addInfo,
    setCommonDialogAction,
    hideCommonDialogAction,
    setPageTitleAction,
    serviceWorkerUpdate,
    showToast
} from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import MasterDataUtilities from './data/MasterDataUtilities';
import LoggingUtilities from './data/LoggingUtilities';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { DuThemeUtilities, DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { Security } from '@okta/okta-react';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { withStyles } from '@material-ui/core/styles';
import LogoPng from './Images/Logo_Small_Transparent.png';
import LogoWebP from './Images/Logo_Small_Transparent.webp';
import BackgroundPng from './Images/Bowl_Mixed_3.png';
import BackgroundWebP from './Images/Bowl_Mixed_3.webp';
import { withLocalize } from 'react-localize-redux';
import APP_TRANSLATIONS from './data/appTranslations';
import { createHashHistory } from 'history';
import InitializeApplication from './pages/InitializeApplication';
import LogOut from './pages/LogOut';
import TemplateHome from './apps/Template/pages/TemplatesHome';
import Home from './pages/Home';
import { History, Assignment, DirectionsRun } from '@material-ui/icons';
import HeaderButtons from './components/HeaderButton';
import DataHome from './apps/Data/pages/DataHome';
import LogHome from './apps/Log/pages/LogHome';

const SITE_NAME = 'Breeding Summary Data';
const DEFAULT_PATH = '/Home/';

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];

const styles = (theme) => ({
    'button[class*="DrcButton-shadowSecondary"]': {
        backgroundColor: 'red !important'
    },
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },

        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },

        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        },
        '.MuiButton-root': {
            fontSize: '1rem',
            boxShadow: 'none'
        }
    },
    logoLink: {
        left: 'calc(50vw - 70px)',
        position: 'absolute'
    },
    logo: {
        // height: 40,
        // ['@media ' + DrcMediaQueries.mobileL]: {
        //     display: 'none'
        // }
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        // background: 'transparent',
        // backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 97),
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(8),
            backgroundColor: '#FFF',
            textShadow: `none`,
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: '#000'
            }
        },
        '& .title': {
            textShadow: `none`,
            fontSize: 22
        },
        '& .appBar': {
            boxShadow: `none`,
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: `absolute`,
            top: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none'
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2)
        },
        '& .menuButton': {
            // border: `1px solid ${DuThemeUtilities.DefaultColors.primary.green}`
            border: `none`
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsla(341, 57%, 15%, 1)',
            '& .title': {
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        }
    },
    STRAW: {
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red), 80),
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsl(4,20%,20%)'
        },
        '& .p-datatable-thead': {
            backgroundColor:
                DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red), 95) + '!important',
            '& >tr>th': {
                backgroundColor:
                    DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red), 95) + '!important'
            },
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: 'hsl(4,20%,60%) !important',
                color: '#000',
                '& >tr>th': {
                    backgroundColor: 'hsl(4,20%,60%) !important',
                    color: '#000'
                }
            }
        }
    },
    BLACK: {
        backgroundColor:
            DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 80) + '!important',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsl(269,20%,20%) !important'
        },
        '& .p-datatable-thead': {
            backgroundColor:
                DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 95) + '!important',
            '& >tr>th': {
                backgroundColor:
                    DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 95) +
                    '!important'
            },
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: 'hsl(269,20%,60%) !important',
                color: '#000',
                '& >tr>th': {
                    backgroundColor: 'hsl(269,20%,60%) !important',
                    color: '#000'
                }
            }
        }
    },
    RASP: {
        backgroundColor:
            DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 80) + '!important',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsl(341,20%,20%) !important'
        },
        '& .p-datatable-thead': {
            backgroundColor:
                DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 95) + '!important',
            '& >tr>th': {
                backgroundColor:
                    DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 95) +
                    '!important'
            },
            '@media (prefers-color-scheme: dark) !important': {
                backgroundColor: 'hsl(341,20%,60%)',
                color: '#000',
                '& >tr>th': {
                    backgroundColor: 'hsl(341,20%,60%)',
                    color: '#000'
                }
            }
        }
    },
    BLUE: {
        backgroundColor:
            DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 80) + '!important',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsl(197,20%,20%)'
        },
        '& .p-datatable-thead': {
            backgroundColor:
                DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 95) + '!important',

            '& >tr>th': {
                backgroundColor:
                    DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 95) +
                    '!important'
            },
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: 'hsl(197,20%,60%) !important',
                color: '#000',
                '& >tr>th': {
                    backgroundColor: 'hsl(197,20%,60%) !important',
                    color: '#000'
                }
            }
        }
    },
    loaderWithMessage: {
        width: '644px',
        left: 'calc(50vw - 322px)',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: '#000',
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#ddd'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    appmain: {
        padding: '4rem 0rem 2rem 0rem',
        minHeight: '100vh'
    }
});

const allLinks = [
    {
        title: <DrcTranslate>{'Summary'}</DrcTranslate>,
        icon: <Assignment style={{ width: '18px', height: '18px' }} />,
        url: '/Home/Summary/',
        requiresGroups: adminGroups
    },
    {
        title: <DrcTranslate>{'Log'}</DrcTranslate>,
        icon: <History style={{ width: '18px', height: '18px' }} />,
        url: '/Home/Log/',
        requiresGroups: adminGroups
    },
    { title: <DrcTranslate>{'Sign Out'}</DrcTranslate>, url: '/LogOut/', icon: <DirectionsRun /> }
];
class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createHashHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: { ...DuCommonTranslations.COMMON_TRANSLATIONS, ...APP_TRANSLATIONS },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : DEFAULT_PATH);

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Polymorphism);
    }

    closeCommonDialog() {
        this.props.hideCommonDialog();
    }

    handleCloseNotification = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        let { classes } = this.props;
        return (
            <div className={`${classes.appmain} ${classes[this.props.berry]}`}>
                <DrcThemeProvider fontFamily="Rubik" theme={this.props.theme}>
                    <DrcEnvironmentMarker />
                    <Helmet>
                        <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                    </Helmet>
                    <Router history={Router.browserHistory}>
                        <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                            <DrcSecureHeader
                                title={this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}
                                allLinks={window.location.pathname === '/' ? [] : allLinks}
                                fullWidth={true}
                                logo={
                                    <Link aria-label="Logo" to={DEFAULT_PATH} className={classes.logoLink}>
                                        <DrcImage src={LogoPng} webp={LogoWebP} className={classes.logo} alt="Driscoll's Logo" />
                                    </Link>
                                }
                                className={classes.header}
                            >
                                <HeaderButtons />
                            </DrcSecureHeader>

                            <Suspense
                                fallback={
                                    <DrcMain transparent>
                                        <DrcLoading />
                                    </DrcMain>
                                }
                            >
                                <Switch>
                                    <Route
                                        path="/"
                                        exact
                                        render={(props) => (
                                            <DrcPageLogin
                                                {...props}
                                                poly
                                                setPageTitle={setPageTitleAction}
                                                backgroundPng={BackgroundPng}
                                                backgroundWebP={BackgroundWebP}
                                            />
                                        )}
                                    />
                                    <DrcSecureGroupRoute
                                        path="/InitializeApplication/"
                                        exact
                                        component={InitializeApplication}
                                        groupsAllowed={adminGroups}
                                    />
                                    <DrcSecureGroupRoute path="/Home/" component={Home} groupsAllowed={adminGroups} />
                                    <DrcSecureGroupRoute path="/Summary/" exact component={DataHome} groupsAllowed={adminGroups} />
                                    <DrcSecureGroupRoute path="/Log/" exact component={LogHome} groupsAllowed={adminGroups} />
                                    <DrcSecureGroupRoute path="/Template/" exact component={TemplateHome} groupsAllowed={adminGroups} />
                                    <Route
                                        path="/implicit/callback"
                                        render={(props) => (
                                            <DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
                                        )}
                                    />
                                    <Route path="/LogOut/" exact component={LogOut} />
                                    <Route component={DrcPageNotFound} />
                                </Switch>
                            </Suspense>
                        </Security>
                        <DrcVersionInfo allowClick={this.props.isInitialized} />
                        <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingMessage} />
                        <DrcDialog
                            isError={this.props.commonDialog.isMessage}
                            title={this.props.commonDialog.title}
                            open={this.props.commonDialog.show}
                            buttons={
                                <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                    OK
                                </DrcButton>
                            }
                        >
                            {this.props.commonDialog.content}
                        </DrcDialog>
                        <DrcDialog
                            title={'New version available'}
                            open={this.props.serviceWorkerUpdated}
                            buttons={
                                <>
                                    <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                    <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                        Ok
                                    </DrcButton>
                                </>
                            }
                        >
                            <div>Refresh to update your app?'</div>
                        </DrcDialog>
                    </Router>
                    {this.props.toastMessage && (
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            className={`${this.props.toastType === 'success' && classes.successToast}  ${
                                this.props.toastType === 'error' && classes.errorToast
                            }`}
                            open={this.props.toastMessage !== ''}
                            autoHideDuration={3000}
                            onClose={this.handleCloseNotification}
                            message={<span id="message-id">{this.props.toastMessage}</span>}
                        />
                    )}
                </DrcThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        toastMessage: state.rootReducer.toastMessage,
        isInitialized: state.masterReducer.isInitialized,
        commonDialog: state.rootReducer.commonDialog,
        toastType: state.rootReducer.toastType,
        loadingMessage: state.rootReducer.loadingScreenMessage,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        berry: state.masterReducer.berry,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration
    };
}

const mapDispatchToProps = (dispatch) => ({
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    addInfo: (info) => dispatch(addInfo(info)),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    hidecommonDialog: () => dispatch(hideCommonDialogAction()),
    setPageTitleAction: () => dispatch(setPageTitleAction()),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate()),
    showToast: (message, type) => dispatch(showToast(message, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
