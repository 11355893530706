import { SET_TEMPLATE_DATA } from '../actions/TemplateActions';

const initialState = {
    template: {}
};

const TemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEMPLATE_DATA:
            let template = action.payload;
            return Object.assign({}, state, {
                template
            });
        default:
            return state;
    }
};

export default TemplateReducer;
