import React, { Component } from 'react';
import { DrcButton, DrcDialog, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import FileUploader from './FileUploader';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withStyles } from '@material-ui/styles';

const styles = (_theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'calc(50% - 50px)',
            width: '100%'
        },
        '& .MuiDialogTitle-root': {
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid',
            marginBottom: 0
        },
        '& .MuiDialogActions-root': {
            borderTop: 'none !important',
            marginTop: 0
        },
        '& .MuiTypography-h6': {
            fontSize: '2rem',
            fontFamily: 'rubik'
        }
    },
    errBtn: {
        marginLeft: '0 !important',
        textDecoration: 'underline'
    }
});
class ImportExcel extends Component {
    removeExcelDrop(removeUploadedFile) {
        this.removeUploadedFile = removeUploadedFile;
    }
    handleClose = () => {
        this.removeUploadedFile();
        this.props.handleImportClose();
    };
    render() {
        const {
            title,
            showUploadDialog,
            handleDataUpload = () => { /* todo */ },
            handleParsedFile = () => { /* todo */ },
            showHeaderDialog,
            closeHeaderDialog = () => { /* todo */ },
            missingHeaders = [],
            classes,
            isFileValid,
            showError,
            handleError = () => { /* todo */ },
            openHeaderDialog = () => { /* todo */ }
        } = this.props;
        return (
            <>
                <DrcDialog
                    title={title}
                    open={showUploadDialog}
                    buttons={
                        <div>
                            {!!missingHeaders.length && (
                                <DrcButton isSecondary style={{ color: 'red', border: '1px solid red' }} onClick={openHeaderDialog}>
                                    {<DrcTranslate>{'Missing Headers'}</DrcTranslate>}
                                </DrcButton>
                            )}
                            {showError && (
                                <DrcButton
                                    onClick={handleError}
                                    className={classes.errBtn}
                                    disabled={!showError}
                                    style={{ color: DuThemeUtilities.DefaultColors.primary.red }}
                                >
                                    {<DrcTranslate>{'Errors'}</DrcTranslate>}
                                </DrcButton>
                            )}
                            <DrcButton isPrimary disabled={!isFileValid} onClick={handleDataUpload}>
                                {<DrcTranslate>{'Submit'}</DrcTranslate>}
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.handleClose}>
                                {<DrcTranslate>{'Close'}</DrcTranslate>}
                            </DrcButton>
                        </div>
                    }
                    className={classes.dialog}
                >
                    <FileUploader parsed={handleParsedFile} removeUpload={this.removeExcelDrop.bind(this)} />
                </DrcDialog>
                <DrcDialog
                    title={<DrcTranslate>{'Missing Columns'}</DrcTranslate>}
                    open={showHeaderDialog}
                    buttons={
                        <DrcButton isPrimary onClick={closeHeaderDialog}>
                            {<DrcTranslate>{'Close'}</DrcTranslate>}
                        </DrcButton>
                    }
                    className={classes.dialog}
                >
                    <h4>{<DrcTranslate>{'Header Missing Msg'}</DrcTranslate>}:</h4>
                    <ul>
                        {missingHeaders.map((col, indx) => (
                            <li key={indx}>{col}</li>
                        ))}
                    </ul>
                </DrcDialog>
            </>
        );
    }
}

export default withStyles(styles)(ImportExcel);
