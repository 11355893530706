import { Middleware } from '@driscollsinc/one-ring';
import logData from '../json/logData';

const logDataFunc = { ...Middleware.CreateHandler('GET', Middleware.NoOp) };

function ConfigureFakeData() {
    if (!Middleware.CheckRegisteredFakeDataHandlers('LogData')) {
        Middleware.RegisterFakeDataHandler('LogData', logData, logDataFunc);
    }
}

const logDataFake = {
    ConfigureFakeData
};

export default logDataFake;
