import { DrcButton, DrcDialog, DrcSelect, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import React from 'react';
import { connect } from 'react-redux';
import { hideLoadingScreenAction, showLoadingScreenAction, showToast } from '../../../actions/actions';
import DrcDateRangePicker from '../../../components/DrcDateRangePicker';
import APIEndPoints from '../services/api';
import { Middleware } from '@driscollsinc/one-ring';
import { middlewareConfig } from '../../../data/constants';

const GraphTypes = [
    { label: 'Diameter', value: 'Diameter', berries: ['BLUE'] },
    { label: 'Flavor', value: 'Flavor', berries: ['BLUE', 'STRAW', 'RASP', 'BLACK'] },
    { label: 'Yield (Kg/Ha)', value: 'Yield', berries: ['BLUE', 'STRAW', 'RASP', 'BLACK'] },
    { label: 'Fruit Size', value: 'Fruit Size', berries: ['STRAW'] },
    { label: 'Brix', value: 'Brix', berries: ['BLUE', 'STRAW', 'RASP', 'BLACK'] }
];

const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        }
    },
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            overflow: 'hidden',
            maxWidth: '900px !important', //TO override dialog maxWidth
            minWidth: '400px',
            maxHeight: '70vh'
        }
    },
    asyncItem: {
        height: '45px',
        width: '100%',
        alignSelf: 'center',
        marginTop: '1px'
    },
    deleteButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: '#e34843',
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: '#ec110a'
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: '#ec110a',
                backgroundColor: '#0000'
            }
        }
    }
});

class GraphDataTemplate extends React.Component {
    state = {
        showDeleteDialog: false,
        saveEnabled: false,
        graphType: GraphTypes.filter((e) => e.berries.includes(this.props.berry))[0],
        startDate: new Date(),
        endDate: new Date(),
        poolweekStart: null,
        poolweekEnd: null,
        errors: [],
        PoolWeeks: [],
        isDefault: false,
        startDateParam: null,
        endDateParam: null,
        rangeEnabled: false
    };

    deletingItem = null;

    handleGraphTypeChange = (option, _key) => {
        this.setState({ graphType: option }, () => {
            this.validate();
        });
    };

    validate = () => {
        this.setState({
            saveEnabled:
                (this.state.graphType && this.state.graphType.label) ||
                (this.state.graphType && this.state.graphType.length > 0 && this.state.rangeEnabled)
        });
    };

    handleDownload = async () => {
        let cols = [
            { name: 'Field Code', key: 'fieldCode' },
            { name: 'Cycle', key: 'cycle' },
            { name: 'Planting Date', key: 'plantingDate' },
            { name: 'Selection', key: 'selection' },
            { name: 'Graph Type', key: 'graphType' }
        ];
        let poolweeks = [];
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            this.showMessage('Loading poolweek...');
            let response = await Middleware.Send(
                '',
                token,
                APIEndPoints.GET_POOLWEEK(this.state.startDateParam, this.state.endDateParam),
                'GET',
                {},
                {
                    ...middlewareConfig(false, true),
                    allowEmptyValues: true
                }
            );
            poolweeks = response.Data.map((item) => {
                return { name: item.PoolWeek, key: item.PoolWeek };
            });
            this.props.showToast(response.Message, 'success');
        } catch (error) {
            this.props.showToast('An error occurred', 'error');
        } finally {
            this.hideMessage();
            this.props.closeDialog();
        }
        if (poolweeks.length === 0) {
            this.props.showToast('There are no poolweeks for the selected date range', 'error');
            this.props.closeDialog();
            return;
        }
        let graphTypes = this.state.graphType.label ? [this.state.graphType] : this.state.graphType;
        cols = [...cols, ...poolweeks];
        let rows = [];
        graphTypes.map((gItem) => {
            rows.push(...this.getRow(gItem.value, cols));
        });

        this.props.closeDialog();
        DuExcelUtilities.Write('graph_GraphTemplate.xlsx', cols, rows);
    };

    getRow = (graphType, cols) => {
        return this.props.selectedRows.map((item) => {
            item['graphType'] = graphType;
            let items = cols.map((col) => {
                let key2 = {};
                if (col.key === 'plantingDate' && item[col.key] && item[col.key].trim('') !== '') {
                    let excelToJsDate = new Date(item[col.key])
                        .toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        })
                        .split('/')
                        .join('-');
                    key2[col.key] = excelToJsDate;
                } else {
                    key2[col.key] = item[col.key];
                }
                return key2;
            });
            let acc = {};
            let ds = items.map((item3) => {
                acc[Object.getOwnPropertyNames(item3)[0]] = item3[Object.getOwnPropertyNames(item3)[0]];
                return acc;
            });
            return acc;
        });
    };

    handleDateRangeChange = (startDate, endDate) => {
        this.setState({ startDate, endDate, isDefault: false });
        let start = this.fmtdate(startDate);
        let end = this.fmtdate(endDate);
        this.setState({ startDateParam: start, endDateParam: end, rangeEnabled: true }, () => {
            this.validate();
        });
    };

    fmtdate = (date) => {
        let mm = date.getMonth() + 1; // getMonth() is zero-based
        let dd = date.getDate();

        return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('');
    };

    handleReset = (startDate, endDate) => {
        this.setState({ startDate, endDate, isDefault: true, rangeEnabled: false }, () => {
            this.validate();
        });
    };

    arrayToCSV = (array) => {
        let str = '';
        return array.reduce((str, next) => {
            str += `${Object.values(next)
                .map((value) => `${value === null ? '' : value}`)
                .join('|')}\r\n`;
            return str;
        }, str);
    };

    showMessage = (messageTitle, message = '') => {
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>
                    <DrcTranslate>{messageTitle}</DrcTranslate>
                </h4>
                <div>
                    <DrcTranslate>{message}</DrcTranslate>
                </div>
            </React.Fragment>
        );
    };

    hideMessage = () => {
        this.props.hideLoadingScreenAction();
    };

    render() {
        const { title, classes } = this.props;
        const { saveEnabled, graphType, startDate, endDate, isDefault } = this.state;
        return (
            <>
                <DrcDialog
                    title={title || ''}
                    className={classes.dialogWrapper}
                    open={this.props.open}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.props.closeDialog();
                                }}
                            >
                                <DrcTranslate>{'Close'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.handleDownload} disabled={!saveEnabled}>
                                <DrcTranslate>{'Download'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <DrcSelect
                                    isMulti
                                    options={GraphTypes.filter((e) => e.berries.includes(this.props.berry))}
                                    label={<DrcTranslate>{'Graph Types'}</DrcTranslate>}
                                    value={graphType}
                                    onChange={(option) => this.handleGraphTypeChange(option)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <DrcDateRangePicker
                                    onChange={this.handleDateRangeChange}
                                    onReset={this.handleReset}
                                    style={{ color: '#563e70' }}
                                    label={'Pool Week Range'}
                                    name="PoolWeek"
                                    color="#4a773c"
                                    months={2}
                                    direction="horizontal"
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    weekDisplay={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    defaultValue={isDefault}
                                />
                            </Grid>
                        </Grid>
                    </>
                </DrcDialog>

                <DrcDialog
                    title="Delete Record?"
                    open={this.state.showDeleteDialog}
                    buttons={
                        <>
                            <DrcButton isPrimary onClick={this.handleDelete}>
                                <DrcTranslate>{'Delete'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton onClick={() => this.cancelDelete()}>
                                <DrcTranslate>{'Oops Just Kidding'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <DrcTranslate>{'Are you sure you want to delete this selection?'}</DrcTranslate>
                    <br />
                    <strong>
                        <DrcTranslate>{'Selection'}</DrcTranslate>:
                    </strong>
                    {this.state.deleteableSelection?.rowData.selection}
                    <br />
                </DrcDialog>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        berry: state.masterReducer.berry
    };
}

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(GraphDataTemplate)));
