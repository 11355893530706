import React, { Component } from 'react';
import { DrcExcelUtilities, DrcButton, DrcDialog, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { DuExcelUtilities, DuThemeUtilities, DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { connect } from 'react-redux';
import { showLoadingScreenAction, hideLoadingScreenAction } from '../../actions/actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = (theme) => ({
    dragArea: {
        width: '100%',
        height: 100,
        border: '2px dashed #888',
        padding: 20,
        margin: '40px 0 10px',
        borderRadius: 10,
        textAlign: 'center'
    },
    uploadBtn: {
        textTransform: 'none'
    },
    submitBtn: {
        width: 150,
        margin: '20px auto',
        display: 'block',
        textAlign: 'center'
    },
    root: {
        color: theme.palette.secondary.light
    }
});

class FileUploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: '',
            isExcelDropInitiated: false,
            sheetSelected: '',
            excelFile: null,
            excelStats: {},
            sheetDialogOpen: false
        };

        this.dropElement = React.createRef();
        this.inputElement = React.createRef();
    }

    componentDidMount() {
        this.connectExcelDrop();
        this.props.removeUpload(this.removeUploadedFile.bind(this));
    }

    removeUploadedFile() {
        this.inputElement.current.value = '';
        this.setState({ fileName: '' });
    }

    componentDidUpdate() {
        this.connectExcelDrop();
    }

    statusUpdate = (status) => {

        if (status.message === 'Starting to Open Excel File') {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>Processing File (Step 1 of 2)</h4>
                    <div>It can take a few seconds to a couple of minutes based on the file size</div>
                </React.Fragment>
            );
        }
    };

    connectExcelDrop = () => {
        if (!this.state.isExcelDropInitiated && this.dropElement.current != null) {
            this.setState({ isExcelDropInitiated: true });

            DuExcelUtilities.CreateExcelDrop(this.dropElement.current, this.handleExcelFile, this.statusUpdate);
        }
    };

    handleExcelFile = async (data, stats) => {
        if (data === null) {
            this.setState({ fileName: <div style={{ color: DuThemeUtilities.DefaultColors.primary.red }}>{stats.message}</div> });
            this.props.hideLoadingScreenAction();
            return;
        }

        this.props.hideLoadingScreenAction();

        if ((data.SheetNames || []).length === 1) {
            this.handleExcelSheet(data, data.SheetNames[0], stats);
        } else {
            //open a dialog to let user choose
            this.setState({ excelFile: data, excelStats: stats, sheetDialogOpen: true, sheetSelected: '' });
        }
    };

    handleExcelSheet = async (data, sheetName, stats) => {
        const processSheetStartTime = new Date();
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>Processing Sheet (Step 2 of 2)</h4>
                <div>It can take a few seconds to a couple of minutes based on the file size</div>
            </React.Fragment>
        );
        const excelData = await DuExcelUtilities.ReadRows(data, sheetName);
        this.props.hideLoadingScreenAction();

        if (excelData.length <= 0) {
            this.setState({
                fileName: (
                    <div style={{ color: DuThemeUtilities.DefaultColors.primary.red }}>No data found. Please upload an excel file with data.</div>
                )
            });
            return;
        }

        console.log(
            `Took ${stats.timeElapsed} to process file and ${DuDateUtilities.TimeSpanToString(
                processSheetStartTime,
                new Date()
            )} to process the sheet`
        );
        this.setState({ fileName: stats.fileName });
        this.props.parsed({ data: excelData, fileName: stats.fileName });
    };

    chooseSheetDialog = async () => {
        this.setState({ sheetDialogOpen: false }, () => {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>Processing Sheet (Step 2 of 2)</h4>
                    <div>It can take a few seconds to a couple of minutes based on the file size</div>
                </React.Fragment>
            );
            setTimeout(() => {
                this.handleExcelSheet(this.state.excelFile, this.state.sheetSelected, this.state.excelStats);
                this.closeSheetDialog();
            }, 0);
        });
    };

    closeSheetDialog = () => {
        this.setState({ excelFile: null, excelStats: null, sheetDialogOpen: false, sheetSelected: '' });
    };

    stopProcessing = () => {
        this.setState({ excelFile: null, excelStats: null, sheetDialogOpen: false, sheetSelected: '', fileName: '' });
        this.inputElement.current.value = '';
    };

    handleRadioButtonChange = (sheetName) => {
        this.setState({ sheetSelected: sheetName });
    };

    render() {
        const { classes } = this.props;

        return (
            <div className="row">
                <div className="col-xs-2"></div>
                <div className="col-xs-8">
                    <div
                        ref={this.dropElement}
                        className={classes.dragArea}
                        onDragOver={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <DrcButton
                            noStyle
                            className={classes.uploadBtn}
                            onClick={() => {
                                this.inputElement.current.click();
                            }}
                        >
                            <AttachFileIcon />
                            {<DrcTranslate>{'Choose Excel'}</DrcTranslate>}
                        </DrcButton>{' '}
                        {<DrcTranslate>{'Drop Excel'}</DrcTranslate>}
                        <br />
                        {this.state.fileName}
                    </div>
                    <div style={{ display: 'none' }}>{DrcExcelUtilities.FileInput(this.handleExcelFile, this.inputElement, this.statusUpdate)}</div>
                </div>
                <div className="col-xs-2"></div>
                <DrcDialog
                    title="Choose Sheet"
                    open={this.state.sheetDialogOpen}
                    buttons={
                        <div style={{ float: 'right', marginRight: -8 }}>
                            <DrcButton isSecondary onClick={this.stopProcessing}>
                                {<DrcTranslate>{'Stop Processing'}</DrcTranslate>}
                            </DrcButton>
                            <DrcButton disabled={this.state.sheetSelected === ''} isPrimary onClick={this.chooseSheetDialog}>
                                {<DrcTranslate>{'Continue'}</DrcTranslate>}
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row">
                        {this.state.excelFile !== null
                            ? this.state.excelFile.SheetNames.map((sheetName) => (
                                  <div className="col-xs-6" key={sheetName}>
                                      <FormControlLabel
                                          name={sheetName}
                                          value={sheetName}
                                          control={
                                              <Radio
                                                  checked={this.state.sheetSelected === sheetName}
                                                  onChange={() => this.handleRadioButtonChange(sheetName)}
                                                  className={classes.root}
                                              />
                                          }
                                          label={sheetName}
                                      />
                                  </div>
                              ))
                            : null}
                    </div>
                </DrcDialog>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(FileUploader));
