import React from 'react';
import { connect } from 'react-redux';
import { logoutAction } from '../actions/actions';
import { useOktaAuth } from '@okta/okta-react';

const LogOut = ({ logout }) => {
    const { oktaAuth } = useOktaAuth();

    logout();
    oktaAuth.signOut('/');
    return (
        <main>
            <h3>Please Wait... Logging you out shortly...</h3>
        </main>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction())
});

export default connect(null, mapDispatchToProps)(LogOut);
