import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (_theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    label: {
        color: '#eee',
        alignSelf: 'center',
        padding: '4px',
        width: '4rem',
        textAlign: 'center',
        fontFamily: 'rubik',
        borderRadius: '4px',
        letterSpacing: '0.5px'
    },
    isYes: {
        background: '#095409'
    },
    isNo: {
        background: '#ff3b3b'
    }
});

function DisplayBool({ classes, val }) {
    const appliedClass = val ? classes.isYes : classes.isNo;
    return (
        <div className={classes.container}>
            {val ? (
                <label className={`${classes.label} ${appliedClass}`}>Yes</label>
            ) : (
                <label className={`${classes.label} ${appliedClass}`}>No</label>
            )}
        </div>
    );
}

export default withStyles(styles)(DisplayBool);
